import { axiosInstance as axios } from "./axiosInstance";

// paths & errors
import {
	API_ADMIN_LOGIN,
	API_ADMIN_LOGOUT,
	API_LOGIN,
	API_LOGOUT,
	API_USERS_ASK_RESET_PASSWORD,
	API_USERS_CONFIRM_EMAIL,
	API_USERS_RESET_PASSWORD,
	API_USERS_SIGNUP,
	API_USERS_UPDATE_ME,
	API_USERS_UPDATE_EMAIL,
	API_USERS_UPDATE_PASSWORD,
	API_USERS_UPDATE_WORKSPACE,
	API_USERS_GET_ME,
	API_USERS,
	API_USERS_COUNT,
} from "./apiPaths";

import {
	ERROR_WRONG_RESPONSE,
	ERROR_INVALID_CREDENTIALS,
	ERROR_UNAUTHORIZED,
	ERROR_EMAIL_ALREADY_TAKEN,
	ERROR_CODE_INVALID,
	ERROR_USER_DO_NO_EXIST,
	ERROR_RESET_PERIOD_EXPIRED,
	ERROR_RESET_CODE_INVALID,
	ERROR_USER_NOT_ACTIVATED,
	ERROR_NOT_ADMIN,
} from "./apiErrors";

import { IUser, IUserFull } from "src/@types/user";
import { convertDataToUser, convertDataToUserFull } from "./conversions";

/*****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * Login through the API
 * @param email
 * @param password
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_INVALID_CREDENTIALS
 * @error error.message
 */
export const apiUserLogin = (
	email: string,
	password: string
): Promise<{ token: string; user: IUser }> => {
	return axios
		.post(
			API_LOGIN,
			{
				email,
				password,
			},
			{ withCredentials: true }
		)
		.then((response) => {
			if (response.data) {
				// process the response to return token & UserType
				try {
					const token: string = response.data.token;
					const user: IUser = convertDataToUser(response.data.user);

					return { token, user };
				} catch (error) {
					console.error("Converting data to User: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) {
				if (!!error.message && error.message === "ERR_USER_NOT_ACTIVATED")
					throw ERROR_USER_NOT_ACTIVATED;
				else throw ERROR_INVALID_CREDENTIALS;
			} else {
				// we don't know :'(
				throw error.message;
			}
		});
};

/**
 * Logout through the API
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiUserLogout = () => {
	return axios.get(API_LOGOUT, { withCredentials: true }).catch((error) => {
		console.error(error);
		if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
		else throw error.message;
	});
};

/**
 * Allow user to sign up
 * @param firstName
 * @param lastName
 * @param email
 * @param password
 * @error ERR_EMAIL_ALREADY_TAKEN
 * @error error.message
 * @returns info of the newly created user, including the ID which will be used to confirm the email.
 */
export const apiUserSignUp = (
	firstName: string,
	lastName: string,
	email: string,
	password: string
): Promise<IUser> => {
	return axios
		.post(
			API_USERS_SIGNUP,
			{
				first_name: firstName,
				last_name: lastName,
				email: email,
				password: password,
			},
			{ withCredentials: true } // creds are also used in CORS...
		)
		.then((response) => {
			if (response.data) {
				// process the response to return token & UserType
				try {
					return convertDataToUser(response.data);
				} catch (error) {
					console.error("Converting data to User: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error && !!error.message && error.message === "ERR_EMAIL_ALREADY_EXISTS")
				throw ERROR_EMAIL_ALREADY_TAKEN;
			else if (!!error.message) throw error.message;
			else throw error.message;
		});
};

/**
 * Confirm the email of a user with a code (sent by email)
 * @TODO Change the output format
 * @param userId
 * @param code
 * @error ERROR_CODE_INVALID
 * @error error.message
 * @returns token & user
 */
export const apiConfirmEmail = (
	userId: string | number,
	code: string
): Promise<{ token: string; user: IUser }> => {
	return axios
		.post(
			API_USERS_CONFIRM_EMAIL.replace(":id", userId.toString()),
			{
				code: code,
			},
			{ withCredentials: true } // creds are also used in CORS...
		)
		.then((response) => {
			if (response.data) {
				const token: string = response.data.token;
				const user = convertDataToUser(response.data.user);
				return { token, user };
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 403) throw ERROR_CODE_INVALID;
			else throw error.message;
		});
};

/**
 * Get the user's info
 * @error ERROR_CODE_INVALID
 * @error error.message
 * @returns user info
 */
export const apiGetMyInfo = (): Promise<IUser> => {
	return axios
		.get(API_USERS_GET_ME, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToUser(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};

/**
 * Update current workspace for the user (it is used to load directly the good ws after login)
 * @param workspaceId
 */
export const apiUpdateMyWorkspace = (workspaceId: number) => {
	return axios
		.post(
			API_USERS_UPDATE_WORKSPACE,
			{ current_workspace_id: workspaceId },
			{ withCredentials: true }
		)
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

/**
 * Update current user's info
 * @param firstName
 * @param lastName
 * @param displayName
 * @param image
 * @param countryCode
 * @param about
 * @returns updated user info
 */
export const apiUpdateMyInfo = (
	firstName: string,
	lastName: string,
	displayName: string,
	image: File | string | null,
	countryCode: string | null,
	about: string | null
): Promise<IUser> => {
	const formData = new FormData();
	formData.append("first_name", firstName);
	formData.append("last_name", lastName);
	formData.append("display_name", displayName);
	if (!!image && image instanceof File) formData.append("image", image);
	if (countryCode) formData.append("country_code", countryCode);
	if (about) formData.append("about", about);
	return axios
		.post(API_USERS_UPDATE_ME, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToUser(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

/**
 *
 * @param email
 * @error ERR_EMAIL_ALREADY_TAKEN
 * @error error.message
 * @returns user info
 */
export const apiUpdateMyEmail = (email: string) => {
	return axios
		.post(
			API_USERS_UPDATE_EMAIL,
			{
				email: email,
			},
			{ withCredentials: true }
		)
		.catch((error) => {
			console.error(error);
			if (!!error && !!error.message && error.message === "ERR_EMAIL_ALREADY_EXISTS")
				throw ERROR_EMAIL_ALREADY_TAKEN;
			else throw error.message;
		});
};

/**
 * Change current user's password
 * @param currentPassword
 * @param newPassword
 * @error ERROR_INVALID_CREDENTIALS
 * @error error.message
 * @returns user info
 */
export const apiUpdateMyPassword = (currentPassword: string, newPassword: string) => {
	return axios
		.post(
			API_USERS_UPDATE_PASSWORD,
			{
				current_password: currentPassword,
				new_password: newPassword,
			},
			{ withCredentials: true }
		)
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 403) throw ERROR_INVALID_CREDENTIALS;
			else throw error.message;
		});
};

/**
 * It initiates the reset password process. The user will receive a mail with the reset_UUID.
 * @param email account email
 * @error ERROR_USER_DO_NO_EXIST
 * @error error.message
 */
export const apiAskResetPassword = (email: string) => {
	return axios.post(API_USERS_ASK_RESET_PASSWORD, { email: email }).catch((error) => {
		console.error(error);
		if (!!error.statusCode && error.statusCode === 401) throw ERROR_USER_DO_NO_EXIST;
		else throw error.message;
	});
};

/**
 * It allows users to update their password if they have the correct code received by mail.
 * @param email
 * @param code
 * @param password new password
 * @error ERROR_UNAUTHORIZED
 * @error ERROR_RESET_PERIOD_EXPIRED
 * @error ERROR_RESET_CODE_INVALID
 * @error error.message
 */
export const apiResetPassword = (email: string, code: string, password: string) => {
	return axios
		.post(API_USERS_RESET_PASSWORD, {
			email: email,
			code: code,
			new_password: password,
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			if (!!error.statusCode && error.statusCode === 403) {
				if (error.message) {
					const errMsg: string = error.message;
					if (errMsg === "ERR_RESET_PERIOD") throw ERROR_RESET_PERIOD_EXPIRED;
					else if (errMsg === "ERR_INVALID_CODE") throw ERROR_RESET_CODE_INVALID;
					else throw error.message;
				} else throw error.message;
			} else throw error.message;
		});
};

/**
 * ADMIN Login through the API
 * @param email
 * @param password
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_INVALID_CREDENTIALS
 * @error error.message
 */
export const apiAdminLogin = (
	email: string,
	password: string
): Promise<{ token: string; user: IUser }> => {
	return axios
		.post(
			API_ADMIN_LOGIN,
			{
				email,
				password,
			},
			{ withCredentials: true }
		)
		.then((response) => {
			if (response.data) {
				// process the response to return token & UserType
				try {
					const token: string = response.data.token;
					const user: IUser = convertDataToUser(response.data.user);
					return { token, user };
				} catch (error) {
					console.error("Converting data to User: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) {
				if (error.message) {
					const errMsg: string = error.message;
					if (errMsg === "ERR_BAD_CREDENTIALS") throw ERROR_INVALID_CREDENTIALS;
					else if (errMsg === "ERR_NOT_ADMIN") throw ERROR_NOT_ADMIN;
					else throw error.message;
				} else throw ERROR_INVALID_CREDENTIALS;
			} else {
				// we don't know :'(
				throw error.message;
			}
		});
};

/**
 * ADMIN Logout through the API
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminLogout = () => {
	return axios.get(API_ADMIN_LOGOUT, { withCredentials: true }).catch((error) => {
		console.error(error);
		if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
		else throw error.message;
	});
};

/**
 * ADMIN Get the list of all users
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminUsers = (): Promise<IUserFull[]> => {
	return axios
		.get(API_USERS, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					let users: IUserFull[] = [];
					for (let x in response.data) users.push(convertDataToUserFull(response.data[x]));
					return users;
				} catch (error) {
					console.error("Converting data to UserFull: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};

/**
 * ADMIN Get the count of all users
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminUserCount = (): Promise<number> => {
	return axios
		.get(API_USERS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};