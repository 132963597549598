// routes
import { PATH_AUTH } from "../routes/paths";
// API
import { setBearerToken, deleteBearerToken } from "src/api";
import localStorageAvailable from "src/utils/localStorageAvailable";
// Type
import { AuthUserType } from "src/auth/types";
// UUID
import { v4 as uuidv4 } from "uuid";

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join("")
	);
	return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
	if (!accessToken) {
		return false;
	}

	const decoded = jwtDecode(accessToken);

	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
	// eslint-disable-next-line prefer-const
	let expiredTimer;

	const currentTime = Date.now();

	// Test token expires after 10s
	// const timeLeft = currentTime + 10000 - currentTime; // ~10s
	let timeLeft = (exp * 1000 - currentTime)*1000; // setTimeout is using milliseconds
	const maxTime = 7 * 24 * 60 * 60 * 1000;
	if (timeLeft > maxTime) timeLeft = maxTime; // maximum 7 days

	// DEBUG
	// console.log("Current time : "+currentTime.toString());
	// console.log("Expiring : "+exp.toString());
	// console.log("Time left : "+timeLeft.toString());

	clearTimeout(expiredTimer);
	expiredTimer = setTimeout(() => {
		alert(`Token expired`);
		localStorage.removeItem("accessToken");
		window.location.href = PATH_AUTH.login;
	}, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
	if (accessToken) {
		localStorage.setItem("accessToken", accessToken);

		//axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
		setBearerToken(accessToken);

		// This function below will handle when token is expired
		const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
		tokenExpired(exp);
	} else {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("user");

		//delete axios.defaults.headers.common.Authorization;
		deleteBearerToken();
	}
};

// ----------------------------------------------------------------------

export const setUser = (user: AuthUserType | null) => {
	if (user) localStorage.setItem("user", JSON.stringify(user));
	else localStorage.removeItem("user");
};

export const getUserFromStorage = (): AuthUserType | null => {
	if (localStorageAvailable()) {
		const user = localStorage.getItem("user");
		if (!!user) return JSON.parse(user);
		else return null;
	} else return null;
};

// ----------------------------------------------------------------------

export const getDistinctUserId = (): string => {
	const existingDistinctUserId = localStorage.getItem("distinctUserId");
	if (!!existingDistinctUserId) return existingDistinctUserId;
	else {
		const newDistinctUserId = uuidv4().toString();
		localStorage.setItem("distinctUserId", newDistinctUserId);
		return newDistinctUserId;
	}
};
