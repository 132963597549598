import { orderBy } from "lodash";
// types
import { IElementShort } from "src/@types/element";
import { IElementFilters, INarrativeFilters } from "src/@types/filter-search";
import { INarrativeShort } from "src/@types/narrative";

// ----------- DEFAULT ELEMENT VALUES -----------------

export const defaultElementFilters: IElementFilters = {
	name: "",
	tags: [],
	period: { min: 0, max: 0 },
};

export const defaultElementSort = "dateAsc";

// ----------- DEFAULT NARRATIVE VALUES -----------------

export const defaultNarrativeFilters: INarrativeFilters = {
	name: "",
};

export const defaultNarrativeSort = "nameAsc";

// ----------- ELEMENT MANAGEMENT -----------------

export function equalDefaultElementFilters(filters: IElementFilters): boolean {
	// name
	if (filters.name !== defaultElementFilters.name) return false;
	// tags
	if (!(filters.tags.length === 0 && defaultElementFilters.tags.length === 0)) {
		if (filters.tags.length === 0 && defaultElementFilters.tags.length !== 0)
			return false; // common case
		else {
			// full check
			if (new Set(filters.tags).size !== new Set(defaultElementFilters.tags).size) return false;
			else
				for (const tag of filters.tags) if (!defaultElementFilters.tags.includes(tag)) return false;
		}
	}
	// period
	if (
		filters.period.min !== defaultElementFilters.period.min ||
		filters.period.max !== defaultElementFilters.period.max
	)
		return false;
	// otherwise true
	return true;
}

export function applyElementFilter(
	elements: IElementShort[],
	filters: IElementFilters,
	sortBy: string
): IElementShort[] {
	const { name, tags, period } = filters;

	// SORT BY
	if (sortBy === "lastUpdated") elements = orderBy(elements, ["updatedAt"], ["desc"]);
	if (sortBy === "nameAsc") elements = orderBy(elements, ["name"], ["asc"]);
	if (sortBy === "nameDesc") elements = orderBy(elements, ["name"], ["desc"]);
	if (sortBy === "dateAsc")
		elements = orderBy(
			elements,
			[
				function (element) {
					return element.dates.startDate?.date;
				},
			],
			["asc"]
		);
	if (sortBy === "dateDesc")
		elements = orderBy(
			elements,
			[
				function (element) {
					return element.dates.startDate?.date;
				},
			],
			["desc"]
		);

	// FILTER ELEMENTS
	if (equalDefaultElementFilters(filters))
		// not necessary to filter
		return elements;

	if (!!name) {
		elements = elements.filter((element) =>
			element.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
		);
	}
	if (!(tags.length === 0 && period.min === 0 && period.max === 0)) {
		if (tags.length > 0) {
			elements = elements.filter((element) => {
				// if an element contains at least one of the tags from the filter, then ok
				for (let i = 0; i < tags.length; i++) if (element.tags.includes(tags[i])) return true;
				return false;
			});
		}
		if (period.min !== 0 || period.max !== 0)
			elements = elements.filter((element) => {
				// if an element has a startDate and if this startDate is between the limits, then ok
				if (!!element.dates.startDate)
					return (
						element.dates.startDate.date.getFullYear() >= period.min &&
						element.dates.startDate.date.getFullYear() <= period.max
					);
				else return false;
			});
	}

	// RETURN
	return elements;
}


// ----------- NARRATIVE MANAGEMENT -----------------

export function equalDefaultNarrativeFilters(filters: INarrativeFilters): boolean {
	// name
	if (filters.name !== defaultNarrativeFilters.name) return false;
	// otherwise true
	return true;
}


export function applyNarrativeFilter(
	narratives: INarrativeShort[],
	filters: INarrativeFilters,
	sortBy: string
): INarrativeShort[] {
	const { name } = filters;

	// SORT BY
	if (sortBy === "lastUpdated") narratives = orderBy(narratives, ["updatedAt"], ["desc"]);
	if (sortBy === "nameAsc") narratives = orderBy(narratives, ["name"], ["asc"]);
	if (sortBy === "nameDesc") narratives = orderBy(narratives, ["name"], ["desc"]);
	

	// FILTER ELEMENTS
	if (equalDefaultNarrativeFilters(filters))
		// not necessary to filter
		return narratives;

	if (!!name) {
		narratives = narratives.filter((narrative) =>
			narrative.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
		);
	}

	// RETURN
	return narratives;
}
