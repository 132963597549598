
export enum CONTACT_STATUS {
		Pending = 0, // waiting for your answer
		Accepted = 1,
		WaitingForAnswer = 2, // waiting for your friend to answer	
}

export interface IContact {
	id: number;
	userId: number,
	name: string;
	image: string;
	message: string;
	statusCode: CONTACT_STATUS;
	friendCode: string;
	createdAt: Date;
	updatedAt: Date;	
}

