import { useTranslation } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { allLangs, defaultLang } from './config-lang';
// localisation of all languages
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr'; 




// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  const getLocale = (language:string):Locale  => {
    switch(language) {
      case 'en': return enGB;
      case 'fr': return fr;
      default: return enGB;
    }
  }
  const currentLocale = getLocale(i18n.language);


  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
    currentLocale,
  };
}
