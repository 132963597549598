import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_WIKI } from "./apiPaths";
// errors
import { ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import { IWikiSearchResult, IWikiPage } from "src/@types/wiki";
import { convertDataToWikiSearchResult, convertDataToWikiPage } from "./conversions";

// ------------------ SEARCH ---------------------------------

export const apiSearchWiki = (query: string, lang: string): Promise<IWikiSearchResult[]> => {
	return axios
		.get(API_WIKI + `/search?query=${query}&lang=${lang}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let results: IWikiSearchResult[] = [];
				for (let r in response.data) results.push(convertDataToWikiSearchResult(response.data[r]));
				return results;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// ------------------ PAGE ---------------------------------

export const apiGetPage = (pageId: string, lang: string): Promise<IWikiPage> => {
	return axios
		.get(API_WIKI + `/page?id=${pageId}&lang=${lang}`, { withCredentials: true })
		.then((response) => {
			if (response.data) return convertDataToWikiPage(response.data);
			else throw ERROR_WRONG_RESPONSE;
		})
		.catch((error) => {
			console.error(`apiGetPage > can't get info for page ${pageId}`);
			console.error(error);
			throw error.message;
		});
};
