import { capitalize } from "lodash";

export const fTag = (tag: string): string => {
	return capitalize(tag);
	// return words(tag)
	// 	.map((word) => {
	// 		return word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase();
	// 	})
	// 	.join(" ")
	// 	.trim();
};

export const fTags = (tags: string[]): string[] => {
	return tags.map((tag) => fTag(tag));
};

