import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
// login form
import AuthLoginForm from './AuthLoginForm';
// locales
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

export default function Login() {
  // locales
	const { translate } = useLocales();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">{`${translate("auth.login.signin")}`}</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{`${translate("auth.login.new_user")}`}</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">            
            {`${translate("auth.login.create_account_link")}`}
          </Link>
        </Stack>

      </Stack>

      <AuthLoginForm />

    </LoginLayout>
  );
}
