import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, Box, BoxProps, Link } from "@mui/material";
// config
import { HEADER } from "src/config-global";
// utils
import { bgBlur } from "src/utils/cssStyles";
// routes
import { PATH_PAGE } from "src/routes/paths";
// components
import Logo from "src/components/logo";
// locales
import { Trans } from "react-i18next";

// ----------------------------------------------------------------------

type Props = {
	isOffset: boolean;
};

export default function Header({ isOffset }: Props) {
	const theme = useTheme();

	return (
		<AppBar color="transparent" sx={{ boxShadow: 0 }}>
			<Toolbar
				sx={{
					justifyContent: "space-between",
					height: {
						xs: HEADER.H_MOBILE,
						md: HEADER.H_MAIN_DESKTOP,
					},
					transition: theme.transitions.create(["height", "background-color"], {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.shorter,
					}),
					...(isOffset && {
						...bgBlur({ color: theme.palette.background.default }),
						height: {
							md: HEADER.H_MAIN_DESKTOP - 16,
						},
					}),
				}}
			>
				<Logo />

				<Link to={PATH_PAGE.contact} component={RouterLink} variant="subtitle2" color="inherit">
					<Trans i18nKey="nav.need_help" />
				</Link>
			</Toolbar>

			{isOffset && <Shadow />}
		</AppBar>
	);
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
	return (
		<Box
			sx={{
				left: 0,
				right: 0,
				bottom: 0,
				height: 24,
				zIndex: -1,
				m: "auto",
				borderRadius: "50%",
				position: "absolute",
				width: `calc(100% - 48px)`,
				boxShadow: (theme) => theme.customShadows.z8,
				...sx,
			}}
			{...other}
		/>
	);
}
