import axios from "axios";
// config
import { HOST_API_URL } from "src/config-global";

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({ baseURL: HOST_API_URL });

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

// DEBUG
// axiosInstance.interceptors.request.use((config: any) => {
// 	console.log("La requête est: " + config.url, config);
// 	return config;
// });

/**
 * JWT management is done in "auth.utils"
 * This function is call from the "setSession" function
 */
export const setBearerToken = (accessToken: string) => {
	axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

/**
 * JWT management is done in "auth.utils"
 * This function is call from the "setSession" function
 */
export const deleteBearerToken = () => {
	delete axiosInstance.defaults.headers.common.Authorization;
};
