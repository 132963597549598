import { createContext, useEffect, useReducer, useCallback, useMemo } from "react";
// utils
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession, setUser, getUserFromStorage, getDistinctUserId } from "./utils";
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from "./types";
import {
	apiConfirmEmail,
	apiGetMyInfo,
	apiUserLogin,
	apiUserLogout,
	apiUserSignUp,
	apiAdminLogin,
	apiAdminLogout,
} from "../api";
//

// ----------------------------------------------------------------------

enum Types {
	INITIAL = "INITIAL",
	LOGIN = "LOGIN",
	REGISTER = "REGISTER",
	CONFIRM = "CONFIRM",
	UPDATE = "UPDATE",
	LOGOUT = "LOGOUT",
	ADMINLOGIN = "ADMINLOGIN",
	ADMINLOGOUT = "ADMINLOGOUT",
}

type Payload = {
	[Types.INITIAL]: {
		isAuthenticated: boolean;
		user: AuthUserType;
		distinctUserId: string;
	};
	[Types.LOGIN]: {
		user: AuthUserType;
	};
	[Types.REGISTER]: {
		user: AuthUserType;
	};
	[Types.CONFIRM]: {
		user: AuthUserType;
	};
	[Types.UPDATE]: {
		user: AuthUserType;
	};
	[Types.LOGOUT]: undefined;
	[Types.ADMINLOGIN]: {
		user: AuthUserType;
	};
	[Types.ADMINLOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
	isInitialized: false,
	isAuthenticated: false,
	user: null,
	distinctUserId: null, // tracking for analytics
};

const reducer = (state: AuthStateType, action: ActionsType) => {
	if (action.type === Types.INITIAL) {
		return {
			isInitialized: true,
			isAuthenticated: action.payload.isAuthenticated,
			user: action.payload.user,
			distinctUserId: action.payload.distinctUserId,
		};
	}
	if (action.type === Types.LOGIN) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === Types.REGISTER) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === Types.CONFIRM) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === Types.UPDATE) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === Types.LOGOUT) {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		};
	}
	// admin
	if (action.type === Types.ADMINLOGIN) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === Types.ADMINLOGOUT) {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		};
	}

	return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(reducer, initialState);

	const storageAvailable = localStorageAvailable();

	// INITIALIZE
	const initialize = useCallback(async () => {
		try {
			const accessToken = storageAvailable ? localStorage.getItem("accessToken") : "";

			if (accessToken && isValidToken(accessToken)) {
				setSession(accessToken);

				// get distinctUserId from local storage or set it up
				const distinctUserId = getDistinctUserId();

				// Call API
				const user = await apiGetMyInfo();

				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: true,
						user,
						distinctUserId,
					},
				});
			} else {
				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: false,
						user: null,
						distinctUserId: getDistinctUserId(),
					},
				});
			}
		} catch (error) {
			console.error(error);
			dispatch({
				type: Types.INITIAL,
				payload: {
					isAuthenticated: false,
					user: null,
					distinctUserId: getDistinctUserId(),
				},
			});
		}
	}, [storageAvailable]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	// LOGIN
	const login = useCallback(async (email: string, password: string) => {
		// Call API
		const { token, user } = await apiUserLogin(email, password);
		setSession(token);
		setUser(user);

		dispatch({
			type: Types.LOGIN,
			payload: {
				user,
			},
		});
	}, []);

	// REGISTER
	const register = useCallback(
		async (email: string, password: string, firstName: string, lastName: string) => {
			const user = await apiUserSignUp(firstName, lastName, email, password);
			setUser(user);

			dispatch({
				type: Types.REGISTER,
				payload: {
					user,
				},
			});
		},
		[]
	);

	// CONFIRM
	const confirm = useCallback(async (userId: string | number, code: string) => {
		const { token, user } = await apiConfirmEmail(userId, code);
		setSession(token);

		dispatch({
			type: Types.CONFIRM,
			payload: {
				user,
			},
		});
	}, []);

	// CONFIRM
	const update = useCallback(async (user: AuthUserType) => {
		setUser(user);
		dispatch({
			type: Types.UPDATE,
			payload: {
				user: user,
			},
		});
	}, []);

	// LOGOUT
	const logout = useCallback(() => {
		// logout call must be an authenticated call to kills all nounce on server-side
		apiUserLogout();
		// remove token after logout
		setSession(null);
		setUser(null);
		dispatch({
			type: Types.LOGOUT,
		});
	}, []);

	// ADMIN LOGIN
	const adminLogin = useCallback(async (username: string, password: string) => {
		// Call API
		const { token, user } = await apiAdminLogin(username, password);
		setSession(token);
		setUser(user);

		dispatch({
			type: Types.ADMINLOGIN,
			payload: {
				user,
			},
		});
	}, []);

	// ADMIN LOGOUT
	const adminLogout = useCallback(() => {
		// logout call must be an authenticated call to kills all nounce on server-side
		apiAdminLogout();
		// remove token after logout
		setSession(null);
		setUser(null);
		dispatch({
			type: Types.ADMINLOGOUT,
		});
	}, []);

	// MEMO
	const memoizedValue = useMemo(
		() => ({
			isInitialized: state.isInitialized,
			isAuthenticated: state.isAuthenticated,
			user: state.user,
			method: "jwt",
			login,
			register,
			confirm,
			update,
			logout,
			adminLogin,
			adminLogout,
			getUserFromStorage,
		}),
		[
			state.isAuthenticated,
			state.isInitialized,
			state.user,
			login,
			logout,
			register,
			confirm,
			update,
			adminLogin,
			adminLogout,
		]
	);

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
