import { Outlet } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
//
import Footer from "./Footer";
import Header from "./Header";
//

// ----------------------------------------------------------------------

export default function MainLayout() {
	// render
	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
			<Header />

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					...{
						pt: { xs: 8, md: 11 },
					},
				}}
			>
				<Outlet />
			</Box>

			<Footer />
		</Box>
	);
}
