import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
// slices
import contextReducer from './slices/context';
import kanbanReducer from './slices/tasks';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const contextPersistConfig = {
  key: 'context',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  kanban: kanbanReducer,
  context: persistReducer(contextPersistConfig, contextReducer),
});

export default rootReducer;
