import { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// components
import { CustomAvatar } from "src/components/custom-avatar";
// api
import { apiGetWorkspace } from "src/api";
import { IWorkspace } from "src/@types/workspace";
// locales
import { useLocales } from "src/locales";
import { DEFAULT_WORKSPACE_IMAGE } from "src/config-global";
// settings
import { useSettingsContext } from "src/components/settings";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavWorkspace() {
	// locales
	const { translate } = useLocales();

	// workspace
	const [workspace, setWorkspace] = useState<IWorkspace | null>(null);

	const { wsId } = useParams();
	const { pathname } = useLocation();

	// theming
	const { themeLayout } = useSettingsContext();
	const isNavMini = themeLayout === "mini";

	// GET DATA
	const getCurrentWorkspaceInfo = async () => {
		if (!!wsId) {
			const workspace = await apiGetWorkspace(parseInt(wsId));
			setWorkspace(workspace);
		}
	};

	// EFFECT
	useEffect(() => {
		getCurrentWorkspaceInfo();
		// eslint-disable-next-line
	}, [wsId, pathname]);

	return (
		<Link
			component={RouterLink}
			to={PATH_DASHBOARD.workspace.list}
			underline="none"
			color="inherit"
			sx={{mb:2}}
		>
			<StyledRoot>
				<CustomAvatar
					src={workspace?.image ?? DEFAULT_WORKSPACE_IMAGE}
					alt={workspace?.name ?? `${translate("nav.workspace_list")}`}
					name={workspace?.name ?? `${translate("nav.workspace_list")}`}
				/>

				{!isNavMini && (
					<Box sx={{ ml: 2, minWidth: 0 }}>
						<Typography variant="subtitle2" noWrap>
							{workspace?.name ?? `${translate("nav.workspace_list")}`}
						</Typography>
					</Box>
				)}
			</StyledRoot>
		</Link>
	);
}
