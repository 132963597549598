import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import("src/pages/auth/LoginPage")));
export const RegisterPage = Loadable(lazy(() => import("src/pages/auth/RegisterPage")));
export const VerifyCodePage = Loadable(lazy(() => import("src/pages/auth/VerifyCodePage")));
export const NewPasswordPage = Loadable(lazy(() => import("src/pages/auth/NewPasswordPage")));
export const ResetPasswordPage = Loadable(lazy(() => import("src/pages/auth/ResetPasswordPage")));

// ADMIN 
export const AdminLoginPage = Loadable(lazy(() => import("src/pages/admin/AdminLoginPage")));
export const AdminDashboardPage = Loadable(lazy(() => import("src/pages/admin/AdminDashboardPage")));
export const AdminUsersPage = Loadable(lazy(() => import("src/pages/admin/AdminUsersPage")));
export const AdminEventsPage = Loadable(lazy(() => import("src/pages/admin/AdminEventsPage")));

// DASHBOARD: USER
export const UserPage = Loadable(
	lazy(() => import("src/pages/dashboard/user/UserPage"))
);
export const UserAccountPage = Loadable(
	lazy(() => import("src/pages/dashboard/user/UserAccountPage"))
);
export const UserContactsPage = Loadable(
	lazy(() => import("src/pages/dashboard/user/UserContactsPage"))
);

// DASHBOARD: WORKSPACE
export const WorkspacePage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/WorkspacePage"))
);
export const WorkspaceListPage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/WorkspaceListPage"))
);
export const WorkspaceNewPage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/WorkspaceNewPage"))
);
export const WorkspaceSettingsPage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/settings/WorkspaceSettingsPage"))
);
export const WorkspaceMembersPage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/settings/WorkspaceMembersPage"))
);
export const WorkspaceInformationPage = Loadable(
	lazy(() => import("src/pages/dashboard/workspace/settings/WorkspaceInformationPage"))
);

// DASHBOARD: CONTEXT
export const ContextPage = Loadable(
	lazy(() => import("src/pages/dashboard/context/ContextPage"))
);
export const ContextElementsPage = Loadable(
	lazy(() => import("src/pages/dashboard/context/ContextElementsPage"))
);
export const ContextRelationsPage = Loadable(
	lazy(() => import("src/pages/dashboard/context/ContextRelationsPage"))
	);

// DASHBOARD: VIEWS
export const ViewsPage = Loadable(
	lazy(() => import("src/pages/dashboard/views/ViewsPage"))
);
export const ViewsGeoMapPage = Loadable(
	lazy(() => import("src/pages/dashboard/views/ViewsGeoMapPage"))
);
export const ViewsTimelinesPage = Loadable(
	lazy(() => import("src/pages/dashboard/views/ViewsTimelinesPage"))
);

// DASHBOARD: ELEMENT
export const ElementEditPage = Loadable(
	lazy(() => import("src/pages/dashboard/context/element/ElementEditPage"))
);
export const ElementViewPage = Loadable(
	lazy(() => import("src/pages/dashboard/context/element/ElementViewPage"))
);


// DASHBOARD: ANALYSIS
export const AnalysisPage = Loadable(
	lazy(() => import("src/pages/dashboard/analysis/AnalysisPage"))
);
export const AnalysisNarrativesPage = Loadable(
	lazy(() => import("src/pages/dashboard/analysis/AnalysisNarrativesPage"))

// DASHBOARD: NARRATIVES
);
export const NarrativeNewPage = Loadable(
	lazy(() => import("src/pages/dashboard/analysis/narrative/NarrativeNewPage"))
);
export const NarrativeEditPage = Loadable(
	lazy(() => import("src/pages/dashboard/analysis/narrative/NarrativeEditPage"))
);
export const NarrativeViewPage = Loadable(
	lazy(() => import("src/pages/dashboard/analysis/narrative/NarrativeViewPage"))
);

// OTHER PAGES
export const PermissionDeniedPage = Loadable(
	lazy(() => import("src/pages/dashboard/PermissionDeniedPage"))
);
export const BlankPage = Loadable(lazy(() => import("src/pages/dashboard/BlankPage")));

// MAIN
export const Page500 = Loadable(lazy(() => import("src/pages/public/Page500")));
export const Page403 = Loadable(lazy(() => import("src/pages/public/Page403")));
export const Page404 = Loadable(lazy(() => import("src/pages/public/Page404")));
export const HomePage = Loadable(lazy(() => import("src/pages/public/HomePage")));
export const TargetPage = Loadable(lazy(() => import("src/pages/public/TargetPage")));
export const AboutPage = Loadable(lazy(() => import("src/pages/public/AboutPage")));
export const Contact = Loadable(lazy(() => import("src/pages/public/ContactPage")));
export const PricingPage = Loadable(lazy(() => import("src/pages/public/PricingPage")));
export const PaymentPage = Loadable(lazy(() => import("src/pages/public/PaymentPage")));
export const TermsAndConditionsPage = Loadable(lazy(() => import("src/pages/public/TermsAndConditionsPage")));
export const PrivacyPolicyPage = Loadable(lazy(() => import("src/pages/public/PrivacyPolicyPage")));
export const ComingSoonPage = Loadable(lazy(() => import("src/pages/public/ComingSoonPage")));
export const MaintenancePage = Loadable(lazy(() => import("src/pages/public/MaintenancePage")));
