import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// sections
import Login from "src/sections/auth/Login";
// locales
import { useLocales } from "src/locales";
// api
import { apiAnalyticsTrack, analyticsEvents } from "src/api";
// auth
import { useAuthContext } from "src/auth/useAuthContext";
//

// ----------------------------------------------------------------------

export default function LoginPage() {
	// locales
	const { translate } = useLocales();
	// auth
	const { getUserFromStorage } = useAuthContext();
  // location
  const location = useLocation();
	// analytics
	useEffect(() => {
		const user = getUserFromStorage();
		apiAnalyticsTrack(user ? user.id : null, analyticsEvents.visitPage, location.pathname, "Login Page");
	}, [getUserFromStorage, location.pathname]);
	// render
	return (
		<>
			<Helmet>
				<title>{`${translate("auth.login.page_title")}`} | Wiser.ly</title>
			</Helmet>

			<Login />
		</>
	);
}
