import { axiosInstance as axios } from "./axiosInstance";

// paths
import { API_TASKS } from "./apiPaths";
// errors
import { ERROR_WRONG_RESPONSE } from "./apiErrors";
import { ITask } from "src/@types/task";
import { convertDataToTask } from "./conversions";

export const apiGetTasksByWorkspace = (workspaceId: number) => {
	return axios
		.get(API_TASKS + "/workspace/" + workspaceId)
		.then((response) => {
			if (response.data) {
				let tasks: ITask[] = [];
				for (let t in response.data) tasks.push(convertDataToTask(response.data[t]));
				return tasks;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiCreateTask = (workspaceId: number, data: Partial<ITask>): Promise<ITask> => {
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	formData.append("name", data.name ?? "");
	formData.append("description", data.description ?? "");
	formData.append("status", data.status ?? "");
	return axios
		.post(API_TASKS, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				const task: ITask = convertDataToTask(response.data);
				return task;
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiUpdateTask = (data: ITask): Promise<ITask> => {
	const formData = new FormData();
	formData.append("task_id", data.id.toString());
	formData.append("name", data.name);
	formData.append("description", data.description ?? "");
	formData.append("status", data.status);
	return axios
		.patch(API_TASKS, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				const task: ITask = convertDataToTask(response.data);
				return task;
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiDeleteTask = (taskId: number) => {
	return axios.delete(API_TASKS + `/${taskId}`).catch((error) => {
		console.error(error);
		throw error.message;
	});
};
