import { memo,useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, BoxProps, Toolbar } from "@mui/material";
// config
import { HEADER } from "src/config-global";
// utils
import { bgBlur } from "src/utils/cssStyles";
// components
import { NavSectionHorizontal } from "src/components/nav-section";
//
import navConfig from "./config-navigation";
import { useParams } from "react-router-dom";
// api
import { apiGetMyInfo } from "src/api";
// redux
import { useSelector } from "src/redux/store";
// auth
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

function NavHorizontal() {
	const theme = useTheme();

	// location
	const { wsId } = useParams();
	// redux
	const { workspace } = useSelector((state) => state.context);
	// user
	const { user } = useAuthContext();
	// state
	const [udpatedNavConfig, setUpdatedNavConfig] = useState(navConfig);

	// dynamics nav to replace ID in the workspaces
	const getNavData = async () => {
		// Get the WS ID
		let ws = wsId as string | null | undefined; // default scenario
		if (ws === undefined || ws === null || ws === "null") {
			// check the store
			if (!!workspace && !!workspace.id) ws = workspace.id.toString();
			// check context
			else if (!!user && !!user.currentWorkspaceId) ws = user.currentWorkspaceId.toString();
			else {
				// otherwise, get CurrentWS from storage
				const storedUser = localStorage.getItem("user");
				if (!!storedUser) {
					const userjson = JSON.parse(storedUser);
					ws = userjson.currentWorkspaceId ?? userjson.personalWorkspaceId;
				} else {
					// last resort: call API
					const fetchedUser = await apiGetMyInfo();
					const wsapi = fetchedUser.currentWorkspaceId ?? fetchedUser.personalWorkspaceId;
					ws = !!wsapi ? wsapi.toString() : null;
				}
			}
		}
		const newNavConfig = navConfig.map((section) => {
			const newItems = section.items.map((element) => {
				return {
					...element,
					path: element.path.replace("workspace/0/", `workspace/${ws}/`),
				};
			});
			return { ...section, items: newItems };
		});
		setUpdatedNavConfig(newNavConfig);
	};

	
	useEffect(() => {
		getNavData();
		// eslint-disable-next-line
	}, [wsId, workspace]);


	return (
		<AppBar
			component="nav"
			color="transparent"
			sx={{
				boxShadow: 0,
				top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
			}}
		>
			<Toolbar
				sx={{
					...bgBlur({
						color: theme.palette.background.default,
					}),
				}}
			>
				<NavSectionHorizontal data={udpatedNavConfig} />
			</Toolbar>

			<Shadow />
		</AppBar>
	);
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
	return (
		<Box
			sx={{
				left: 0,
				right: 0,
				bottom: 0,
				height: 24,
				zIndex: -1,
				width: 1,
				m: "auto",
				borderRadius: "50%",
				position: "absolute",
				boxShadow: (theme) => theme.customShadows.z8,
				...sx,
			}}
			{...other}
		/>
	);
}
