import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_RELATIONS, API_RELATIONS_COUNT } from "./apiPaths";
// errors
import { ERROR_UNAUTHORIZED, ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import { IRelation } from "src/@types/element";
import { convertDataToRelation } from "./conversions";

// ------------------ CREATE OR UPDATE ---------------------------------

export const apiCreateOrUpdateRelation = (data: IRelation): Promise<IRelation> => {
	const postData = {
		origin_id: data.originId,
		link: data.link,
		destination_id: data.destinationId,
	};
	// AXIOS
	return axios
		.post(API_RELATIONS, postData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToRelation(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

// ------------------ GET ---------------------------------

export const apiGetAllRelations = (workspaceId: number): Promise<IRelation[]> => {
	return axios
		.get(API_RELATIONS + `/getAll/${workspaceId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let relations: IRelation[] = [];
				for (let r in response.data) relations.push(convertDataToRelation(response.data[r]));
				return relations;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// -------------------- DELETE -------------------------------

export const apiDeleteRelation = (originId: number, destinationId: number) => {
	const postData = {
		origin_id: originId,
		destination_id: destinationId,
	};
	// AXIOS
	return axios.post(API_RELATIONS+"/delete", postData, { withCredentials: true }).catch((error) => {
		console.error(error);
		// we don't know :'(
		throw error.message;
	});
};


// -------------------- COUNT -------------------------------

/**
 * ADMIN Get the count of all relations
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminRelationCount = (): Promise<number> => {
	return axios
		.get(API_RELATIONS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};