import { axiosInstance as axios } from "./axiosInstance";

// paths & errors
import { API_EVENTS, API_EVENTS_DASHBOARD_VISIT_COUNT, API_EVENTS_PUBLIC_VISIT_COUNT } from "./apiPaths";

import { ERROR_WRONG_RESPONSE, ERROR_UNAUTHORIZED } from "./apiErrors";

import { IEvent } from "src/@types/event";
import { convertDataToEvent } from "./conversions";

/**
 * ADMIN Get the list of all events
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminEvents = (): Promise<IEvent[]> => {
	return axios
		.get(API_EVENTS, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					let events: IEvent[] = [];
					for (let x in response.data) events.push(convertDataToEvent(response.data[x]));
					return events;
				} catch (error) {
					console.error("Converting data to Event: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};

/**
 * ADMIN Get the count of all public visits
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminPublicVisitCount = (days:number): Promise<number> => {
	return axios
		.get(API_EVENTS_PUBLIC_VISIT_COUNT + `/${days}`, { withCredentials: true })
		.then((response) => {
			if (response.data || response.data === 0) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};

/**
 * ADMIN Get the count of all public visits
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminDashboardVisitCount = (days:number): Promise<number> => {
	return axios
		.get(API_EVENTS_DASHBOARD_VISIT_COUNT + `/${days}`, { withCredentials: true })
		.then((response) => {
			if (response.data || response.data === 0) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};
