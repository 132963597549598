import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_ANALYTICS_TRACK } from "./apiPaths";

export enum analyticsEvents {
	signup = "Signup",
	verifyCode = "Verify Code",
	verifyCodeFailed = "Verify Code Failed",
	login = "Login",
	loginFailed = "Login Failed",
	logout = "Logout",
	resetPassword = "Reset Password",
	setNewPassword = "Set New Password",
	visitPage = "Visit Page",
	sendInquiry = "Send Inquiry",
	updateAccountInfo = "Update Account Info",
	updatePassword = "Update Password",	
	createWorkspace = "Create Workspace",
	editWorkspace = "Edit Workspace",
	deleteWorkspace = "Delete Workspace",
	validateContactRequest = "Validate Contact Request",
	ignoreContactRequest = "Ignore Contact Request",
	cancelContactRequest = "Cancel Contact Request",
	createContactRequest = "Create Contact Request",
	updateAccessRight = "Update Access Right",
	createElement = "Create Element",
	updateElement = "Update Element",
	deleteElement = "Delete Element",	
	viewNarrative = "View Narrative",
	createNarrative = "Create Narrative",
	updateNarrative = "Update Narrative",
	deleteNarrative = "Delete Narrative",
}

export const apiAnalyticsTrack = (userId: number|null, name: string, url: string, location: string, properties?: any) => {
	const distinctUserId = localStorage.getItem("distinctUserId");
	return axios
		.post(API_ANALYTICS_TRACK, {
			distinctUserId,
			userId,
			name,
			url,
			location,
			properties,
		})
		.catch((error) => {
			console.error(error);
		});
};
