// ----------------------------------------------------------------------

const en = {
	// MAIN NAV HEADER
	nav: {
		home: "Home",
		who_it_is_for: "Who it is for",
		who_we_are: "Who we are",
		where_to_find_us: "Where to find us",
		dashboard: "Dashboard",
		login: "Login",
		sign_up_for_free: "Sign Up for free",
		need_help: "Need Help?",
		workspace_list: "Workspace List",
	},
	// AUTHENTICATION
	auth: {
		register: {
			page_title: "Register",
			signup_for_free: "Sign up for Free",
			main_slogan: "Explore, Learn, Understand",
			get_started_for_free: "Get started absolutely free.",
			already_an_account: "Already have an account?",
			signin: "Sign in",
			by_signin_i_agree: "By signing up, I agree to ",
			tos: "Terms of Service",
			privacy_policy: "Privacy Policy",
			form: {
				label: {
					firstName: "First name",
					lastName: "Last name",
					email: "Email address",
					password: "Password",
				},
				error: {
					first_name_required: "First name required",
					first_name_pattern:
						"First name must start with a letter and can not contain any special character",
					last_name_required: "Last name required",
					last_name_pattern:
						"Last name must start with a letter and can not contain any special character",
					email_required: "Email is required",
					email_valid: "Email must be a valid email address",
					password_required: "Password is required",
					password_min_length: "Password must be at least 8 character",
					password_pattern:
						"Password must be at least 8 characters, contain at least one number and one letter and one special character",
				},
				create_account_button: "Create account",
			},
		},
		verify_code: {
			page_title: "Verify Code",
			main_title: "Please check your email!",
			explanation_text:
				"We have emailed a 6-character confirmation code to {{email}}, please enter the code in below box to verify your email.",
			return_home: "Return home",
			form: {
				error: {
					invalid_code: "This code is invalid",
					code_required: "Code is required",
					no_user_info:
						"It seems we have lost your user information, please restart the register procedure",
				},
				verify_code_button: "Verify",
				verify_success_snackbar: "Verify success!",
			},
		},
		login: {
			page_title: "Login",
			go_to_app: "Login",
			main_slogan: "Hi, Welcome back",
			signin: "Sign in to Wiser.ly",
			new_user: "New user?",
			create_account_link: "Create an account",
			forgot_password_link: "Forgot password?",
			activate_link: "Activate account?",
			form: {
				label: {
					email: "Email address",
					password: "Password",
				},
				error: {
					email_required: "Email is required",
					email_valid: "Email must be a valid email address",
					password_required: "Password is required",
					invalid_credentials: "Invalid login credentials. Please try again.",
					user_not_activated: "You have not activated your account yet, check your email",
				},
				login_button: "Login",
			},
		},
		forgot_password: {
			page_title: "Reset Password",
			main_title: "Forgot your password?",
			explanation_text:
				"Please enter the email address associated with your account and We will email you a link to reset your password.",
			return_to_signin: "Return to sign in",
			form: {
				label: {
					email: "Email address",
				},
				error: {
					email_required: "Email is required",
					email_valid: "Email must be a valid email address",
					user_does_not_exist: "We don't have an account associated with this email",
				},
				send_request_button: "Send Request",
			},
		},
		new_password: {
			page_title: "New Password",
			main_title: "Request sent successfully!",
			explanation_text:
				"We've sent a 6-character confirmation code to your email. Please enter the code in below box to verify your email and then your new password.",
			return_to_signin: "Return to sign in",
			form: {
				label: {
					email: "Email address",
					password: "New Password",
					confirm_password: "Confirm New Password",
				},
				error: {
					email_required: "Email is required",
					email_valid: "Email must be a valid email address",
					code_required: "Code is required",
					password_required: "Password is required",
					confirm_password_required: "Confirm Password is required",
					password_pattern:
						"Password must be at least 8 characters, contain at least one number and one letter and one special character",
					passwords_must_match: "Passwords must match",
					unauthorized: "We can't authorized the request, have you check the email?",
					reset_period_expired:
						"The code you have used was send too long ago, for safety measure, it is valid only 10 minutes. Please restart the procedure.",
					reset_code_invalid: "This code is invalid",
				},
				update_password_button: "Update Password",
				update_success_snackbar: "Change password success!",
			},
		},
	},
	// PUBLIC
	public_page: {
		footer: {
			all_rights_reserved: "© All rights reserved",
			all_rights_reserved_2023: "© 2023. All rights reserved",
			made_by: "made by",
			hero_text:
				"Realize your full knowledge potential with our easy-to-use platform, which allows you to quickly transform your information into valuable knowledge.",
		},
		home: {
			page_title: "Discover History with Wiser.ly",
			hero: {
				main_text: "Discover History<br/>with Wiser.ly",
				secondary_text: "Explore, Learn, Understand.",
				cta: "Try it for free today",
			},
			title: {
				big_title: "Where History Comes Alive",
				overline: "Master the Essentials",
				stories: {
          ancient_egypt: {
            title:"Ancient Egypt",
            description:"From Pharaohs to Pyramids"
          },
          ancient_philosophers: {
            title:"Ancient Philosophers",
            description:"From Thales to Cicero"
          },
          roman_empire: {
            title:"Roman Empire",
            description:"Rise, Expansion, and Fall"
          },
          middle_ages: {
            title:"Middle Ages",
            description:"From Feudalism to the Black Death"
          },
          new_world: {
            title:"Discovery of the New World",
            description:"Explorers and Conquests"
          },
          renaissance: {
            title:"Renaissance",
            description:"The Rebirth of Art and Science"
          },
          enlightment: {
            title:"The Enlightenment",
            description:"Philosophers, Ideas, and Social Change"
          },
          french_revolution: {
            title:"French Revolution of 1789",
            description:"Important Actors, Events, and Places"
          },
          industrial_revolution: {
            title:"The Industrial Revolution",
            description:"Machines, Factories, and Social Impact"
          },
          first_world_war: {
            title:"First World War",
            description:"Causes, Battles, and Consequences"
          },
          second_world_war: {
            title: "Second World War",
            description:"From the Blitzkrieg to Hiroshima"
          },
          cold_War: {
            title:"Cold War",
            description:"Ideological Conflict and Global Tensions"
          },
          internet: {
            title:"History of the Internet",
            description:"From ARPANET to Social Media"
          },
          great_inventions: {
            title:"Great Inventions",
            description:"From the Wheel to the Smartphone"
          },
          european_union: {
            title:"European Union",
            description:"From Coal and Steel to a United Europe"
          },
        }
			},
			argument1: {
				overline: "Simplified, Engaging, Effective",
				title: "Learn History with Ease",
				description:
					"Wiser.ly simplifies learning history with clear lessons and interactive tools. Quickly grasp key concepts and retain them longer.<br/>Whether it is dates or events, Wiser.ly makes learning easy and enjoyable.",
			},
			argument2: {
				overline: "Stories That Bring History to Life",
				title: "History as a Story",
				description:
					"Wiser.ly transforms history into captivating stories, making it easier to connect with the past and remember what truly matters.",
			},
			argument3: {
				overline: "Context at Your Fingertips",
				title: "Explore and Understand",
				description:
					"Dive deep into history with powerful visual tools that bring the past to life. Explore interactive timelines, geographic maps, and relational graphs to see how events, places, and people are interconnected.<br/>Wiser.ly's visualizations make complex historical data easy to explore, helping you grasp the bigger picture and the finer details with clarity.",
				carousel:{
					img_1: "Visualise in a comprehensive way with the GeoMap | Wiser.ly ",
					img_2: "Visualise in a comprehensive way with the Elements View | Wiser.ly ",
					img_3: "Visualise in a comprehensive way with the Relation Graph | Wiser.ly ",
					img_4: "Visualise in a comprehensive way with the Timelines | Wiser.ly ",
				},
			},
			argument4: {
				overline: "Dynamic element and reports",
				title: "Share your findings",
				description:
					"Highlight the desired elements, describe the interesting relationships and give your conclusions in a readable and easy to digest format. You can also explore every item of your reports!",
			},
			argument_final: {
				title: "Share History and Culture with the Next Generations",
				description: "Join us in preserving and passing on the knowledge that shapes our world.<br/>Whether you are a <strong>student, teacher, or history enthusiast</strong>, your contribution helps build a richer, more informed future.",
				cta: "Contact us and Schedule a Demo",
			},
			pricing: {
				overline: "Pricing plans",
				title_1: "The right plan for",
				title_2: "your business",
				description: "Choose the perfect plan for your needs. Always flexible to grow",
			},
			advertisement: {
				title: "Get started with<br/>Wiser.ly today",
				cta: "Join the Community and Get Access Now",
			},
		},
		target: {
			page_title: "Who is it for?",
			hero: {
				main_text: "Transform the Way You Learn History",
				secondary_text: "Empowering Students, Teachers, and Researchers through Engaging Historical Learning",
				cta: "Begin Your Journey",
			},
			overview: {
				big_title: "Unlock the Power of Interactive Learning",
				overline: "A Platform Tailored to Your Needs",
				argument_1:"Interesting<br/>Stories",
				argument_2:"Explore<br/>History",
				argument_3:"Captivating<br/>Lessons",
				argument_4:"Fun<br/>Discoveries",
			},
			student: {
				overline: "For Students Like Lea",
				title: "Better Grades, Engaging Learning",
				description: "Transform history from boring to exciting!<br/>Learn with fun, immersive stories and actively explore the past. With Wiser.ly, history becomes an adventure you won’t want to miss!",
			},
			teacher: {
				overline: "For Teachers Like Jean-Luc",
				title: "Captivating Lessons, Quickly Prepared",
				description: "Save time and simplify your lesson planning!<br/>Create engaging lessons with auto-built visualizations, and let your students explore content on their favorite devices: phones and tablets.",
			},
			enthusiast: {
				overline: "For Enthusiasts Like Marie",
				title: "Communicate Your Discoveries Easily",
				description: "Uncover the hidden insights of history and dive deep into your favorite eras.<br/>Understand key events with ease, and effortlessly share your discoveries with others on Wiser.ly.",
			},
			why_it_is_for_you: {
				overline: "Tailored to Your Needs",
				title: "Perfect for You",
				description: "Wiser.ly makes history exciting for students, effortless for teachers, and insightful for researchers. An essential tool for learning and discovery.",
			},
			advertisement: {
				title: "Get started with<br/>Wiser.ly today",
				cta: "Join the Community and Get Access Now",
			},
		},
		about: {
			page_title: "About us",
			hero: {
				main_text: "Our Journey Through History",
				secondary_text: "Bringing the Past to Life for Future Generations",
			},
			title: {
				main_text: "We are enthousiast and passionate researchers who believe that knowledge and discoveries should be shared among all communities so that everyone can have better understanding about our history and cultures.",
				secondary_text: "<p>Knowing history and culture is essential for new generations as it allows them to understand the roots of their identity, the mistakes of the past, and the lessons that come from them. It nurtures their critical thinking and sense of citizenship, while helping them to appreciate the diversity and richness of the world.</p><p>By grounding themselves in a collective past, they can better build the future, avoid tragic repetitions, and participate in an informed and responsible manner in global society. </p><p>It is a way to develop a deeper awareness of the world and their place within it.</p>",
			 },
			why: {
				title: "Why Wiser.ly?",
				text_1: "A key element of this project is to understand the significant events of History and explain different perspectives from the actors involved.",
				text_2: "By encouraging the dissemination of knowledge and promoting access to it, we want to contribute to a better and more informed future for all. This commitment guides our every action in this important endeavour.",
				cta: "Check our work",
			},
			vision: {
				text: "Our vision is to inspire people to improve their knowledge so that they can make better choices for our shared future",
			},
		},
		contact: {
			page_title: "Contact us",
			hero: {
				main_text: "Where to find us?",
				discord_name: "Our community server",
				linkedin_name: "Our company page",
				twitter_name: "Our feed",
			},
			form: {
				title: "Feel free to contact us.<br/>We'll be glad to hear from you.",
				label: {
					name: "Name",
					email: "Email",
					subject: "Subject",
					message: "Enter your message here.",
				},
				error: {
					name_required: "Name is required",
					email_required: "Email is required",
					email_valid: "Email must be a valid email address",
					subject_required: "Subject is required, let us know what you want to talk about",
					message_required: "Message is required, please explain why you are contacting us",
				},
				submit_button: "Submit now",
				confirmation_message_1: "Thank you for your message.",
				confirmation_message_2: "We will come back at you very shortly.",
			},
		},
		terms_and_conditions: {
			page_title: "Terms and Conditions",
		},
		privacy_policy: {
			page_title: "Privacy Policy",
		},
	},
	// DASHBOARD
	dashboard: {
		nav: {
			analysis: {
				subheader: "Analysis",
				narratives: "Narratives",
			},
			views: {
				subheader: "Views",
				geo_map: "Geo Map",
				timelines: "Timelines",
			},
			context: {
				subheader: "Context",
				elements: "Elements",
				relations: "Relations",
			},
			workspace: {
				subheader: "Workspace",
				information: "Information",
				members: "Members",
			},
		},
		user: {
			page_title: "User",
			breadcrumb: {
				heading: "User",
				level_1: "User",
			},
			profile: "Profile",
			profile_explanation: "Edit your name, bio or your password",
			contacts: "Contacts",
			contacts_explanation: "Find and add new contacts, so you can collaborate",
		},
		user_account: {
			page_title: "Profile",
			breadcrumb: {
				heading: "Profile",
				level_1: "User",
				level_2: "Profile",
			},
			tabs: {
				general: "General",
				change_password: "Change password",
			},
			profile_form: {
				label: {
					first_name: "First Name",
					last_name: "Last Name",
					display_name: "Display Name",
					avatar: "Avatar",
					country: "Country",
					about: "About",
					dark_mode: "Dark Mode",
				},
				helper: {
					image_formats: "Allowed *.jpeg, *.jpg, *.png, *.gif",
					image_size: "max size of",
				},
				error: {
					first_name_required: "First name required",
					first_name_pattern:
						"First name must start with a letter and can not contain any special character",
					last_name_required: "Last name required",
					last_name_pattern:
						"Last name must start with a letter and can not contain any special character",
					display_name_required: "Display Name is required",
					avatar_required: "Avatar is required",
				},
				save_button: "Save Changes",
				success_snackbar: "Update success!",
				failure_snackbar: "Update failed",
			},
			change_password_form: {
				label: {
					old_password: "Old Password",
					new_password: "New Password",
					confirm_password: "Confirm New Password",
				},
				helper: {
					password: "Password must be minimum 8+, 1 num, 1 letter, 1 special",
				},
				error: {
					old_password_required: "Old Password is required",
					new_password_required: "New Password is required",
					confirm_password_required: "Confirm New Password is required",
					password_pattern:
						"Password must be at least 8 characters, contain at least one number and one letter and one special character",
					passwords_must_match: "Passwords must match",
					new_passwords_must_differ: "New password must be different than old password",
					invalid_credentials: "Old password is not valid",
				},
				save_button: "Save Changes",
				success_snackbar: "Update success!",
				failure_snackbar: "Update failed",
			},
		},
		user_contacts: {
			page_title: "Contacts",
			breadcrumb: {
				heading: "Contacts",
				level_1: "User",
				level_2: "Contacts",
			},
			new_contact_button: "New Contact",
			friend_code: "Your code is: ",
			helper_friend_code: "Communicate this code to your friends so they can add you as contact",
			code_copied_to_clipboard: "Code copied to clipboard",
			table: {
				clear_filter: "Clear",
				status: {
					all: "All",
					pending: "Pending",
					accepted: "Accepted",
					waiting: "Waiting for answer",
				},
				label: {
					name: "Name",
					message: "Message",
					status: "Status",
				},
				action: {
					validate: "Validate",
					ignore: "Ignore",
					cancel: "Cancel",
					delete_confirm_title: "Remove request",
					delete_confirm_text: "Are you sure want to remove this request?",
					delete_button: "Remove",
				},
				accept_success_snackbar: "Contact request accepted",
				delete_success_snackbar: "Contact request removed",
				failure_snackbar: "Error while updating contact request",
			},
			new_dialog_form: {
				title: "Send Contact Request",
				helper: "Your friend will have to accept before he becomes a contact",
				label: {
					friend_code_or_email: "Friend Code or Email",
					message: "Message",
				},
				error: {
					friend_code_or_email_required: "You have to provide either your friend's code or email",
					friend_code_or_email_pattern:
						"The code must be like XXXX-XXXX or the email must be valid",
					email_valid: "Email must be a valid email address",
					invalid_friend: "This code or this email doesn't exist",
					cant_be_own_friend: "This code or this email belongs to you ;-)",
					contact_request_exists: "You already have sent a contact request to this user",
					already_friend: "You are already friend with this user",
				},
				new_button: "Send",
				cancel_button: "Close",
				success_snackbar: "Contact Request send",
			},
		},
		workspace: {
			page_title: "Workspace",
			breadcrumb: {
				heading: "Workspace",
				level_1: "All Workspaces",
				level_2: "Workspace",
			},
			context: "Context",
			views: "Views",
			analysis: "Analysis",
			settings: "Settings",
			elements: "Elements",
			elements_explanation: "View and edit the elements of your context",
			relations: "Relations",
			relations_explanation: "View and edit relations between elements",
			geomap: "Geo Map",
			geomap_explanation: "View all your elements on the world map",
			timelines: "Timelines",
			timelines_explanation: "View all your elements on timelines",
			narratives: "Narratives",
			narratives_explanation: "Present actors perspectives through narratives",
			information: "Information",
			information_explanation:
				"Edit the information of your workspace so others understand what it is about",
			members: "Members",
			members_explanation: "Choose who can access your workspace to collaborate or simply read it",
		},
		workspace_list: {
			page_title: "Workspaces",
			breadcrumb: {
				heading: "All Workspaces",
				level_1: "All Workspaces",
			},
			new_workspace_button: "New Workspace",
			ws_type: {
				all: "All",
				private: "Private",
				public: "Public",
			},
			action: {
				information: "Information",
				members: "Members",
				cancel: "Cancel",
				delete: "Delete",
				delete_confirm_title: "Delete Workspace",
				delete_confirm_text: "Are you sure want to delete this workspace? all data will be lost!",
				delete_button: "Delete",
			},
			delete_success_snackbar: "Workspace deleted",
			delete_failure_snackbar: "Error while deleting the workspace",
		},
		workspace_create: {
			page_title: "New Workspace",
			breadcrumb: {
				heading: "Workspaces",
				level_1: "All Workspaces",
				level_2: "Create",
			},
			new_workspace_button: "New Workspace",
			new_workspace_form: {
				label: {
					name: "Name",
					description: "Description",
					image: "Cover",
				},
				error: {
					name_required: "Name is required",
				},
				cancel_button: "Cancel",
				create_button: "Create",
				success_snackbar: "Workspace Created!",
				failure_snackbar: "Error while creating the workspace",
			},
		},
		workspace_settings: {
			page_title: "Settings",
			breadcrumb: {
				heading: "Settings",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Settings",
			},
			information: "Information",
			information_explanation:
				"Edit the information of your workspace so others understand what it is about",
			members: "Members",
			members_explanation: "Choose who can access your workspace to collaborate or simply read it",
		},
		workspace_information: {
			page_title: "Information",
			breadcrumb: {
				heading: "Information",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Settings",
				level_4: "Information",
			},
			new_workspace_button: "New Workspace",
			error_getting_data: "Getting the workspace information from the server",
			edit_workspace_form: {
				label: {
					name: "Name",
					description: "Description",
					image: "Cover",
				},
				error: {
					name_required: "Name is required",
				},
				cancel_button: "Cancel",
				create_button: "Edit",
				success_snackbar: "Workspace edited!",
				failure_snackbar: "Error while editing the workspace",
			},
		},
		workspace_members: {
			page_title: "Workspace Members",
			breadcrumb: {
				heading: "Members",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Settings",
				level_4: "Members",
			},
			new_contact_button: "New Contact",
			table: {
				clear_filter: "Clear",
				right: {
					all: "All",
					none: "None",
				},
				label: {
					name: "Name",
					friend_code: "Friend Code",
					level: "Access Right",
				},
				action: {
					giveAdmin: "Set as Administrator",
					giveWrite: "Set as Contributor",
					giveRead: "Set as Subscriber",
					giveNone: "Remove rights",
				},
				success_snackbar: "Access Right updated",
				failure_snackbar: "Error while updating Access Right",
			},
		},
		context: {
			page_title: "Context",
			breadcrumb: {
				heading: "Context",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Context",
			},
			context: "Context",
			elements: "Elements",
			elements_explanation: "View and edit the elements of your context",
			relations: "Relations",
			relations_explanation: "View and edit relations between elements",
		},
		context_elements: {
			page_title: "Elements",
			breadcrumb: {
				heading: "Elements",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Context",
				level_4: "Elements",
			},
			new_element_button: "New Element",
			find_relations_dialog: {
				title: "Find new relations",	
				back_button: "Back",
				cancel_button: "Cancel",	
				create_relations_button: "Create new relations",
			},
			delete_confirm_dialog: {
				title: "Delete Element",
				text: "Are you sure want to delete this Element? all data will be lost!",		
				cancel_button: "Cancel",		
				delete_button: "Delete",
				delete_success_snackbar: "Element deleted",
				delete_failure_snackbar: "Error while deleting the Element",
			},
			action: {
				edit: "Edit",
				find_relations: "Find relations",
				relations: "Relations",
				geomap: "GeoMap",
				timelines: "Timelines",
				delete: "Delete",
			},
			empty: {
				text_1: "You don't have any element yet",
				text_2: "Elements are used to build your context.",
				text_3: "Let's create your first Element.",
				button: "Create an Element",
			},
			no_elements: "There are no elements to show",
		},
		context_relations: {
			page_title: "Relations",
			breadcrumb: {
				heading: "Relations",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Context",
				level_4: "Relations",
			},
			new_element_button: "New Element",
			full_screen_button: "Full Screen",
			save_positions_button: "Save positions",
			restore_positions_button: "Restore positions",
			change_element_size_button: "Change elements size based on their period",
			graph_manipulation_helper: "Drag & drop to move, Shift to multi-select, Del/Backspace to delete",
			delete_dialog: {
				delete_node: {
					title: "Delete Elements",
					content:
						"Are you sure you want to delete {{nbNodes}} element(s)? all data contained in these elements will be lost.",
				},
				delete_edge: {
					title: "Delete Relations",
					content: "Are you sure you want to delete {{nbEdges}} relation(s)?",
				},
				delete_both: {
					title: "Delete Elements and Relations",
					content:
						"Are you sure you want to delete {{nbNodes}} elements(s) and {{nbEdges}} relations(s)? all data contained in these elements will be lost.",
				},
				delete_button: "Delete",
				cancel_button: "Cancel",
			},
		},
		views: {
			page_title: "Views",
			breadcrumb: {
				heading: "Views",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Views",
			},
			views: "Views",
			geomap: "Geo Map",
			geomap_explanation: "View all your elements on the world map",
			timelines: "Timelines",
			timelines_explanation: "View all your elements on timelines",
		},
		views_geomap: {
			page_title: "Geo Map",
			breadcrumb: {
				heading: "Geo Map",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Views",
				level_4: "Geo Map",
			},
			new_element_button: "New Element",
		},
		views_timelines: {
			page_title: "Timelines",
			breadcrumb: {
				heading: "Timelines",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Views",
				level_4: "Timelines",
			},
			new_element_button: "New Element",
			no_elements: "There are no elements to show",
		},
		element_cve: {
			tabs: {
				general: "General",
				geo: "Geo",
				medias: "Medias",
				relations: "Relations",
				sources: "Sources",
			},
			drawer: {
				denomination: "Denomination",
				tags: "Tags",
				dates: "Dates",
				geo: "Geo",
				medias: "Medias",
				description: "Description",
				sources: "Sources",
				searches: "Searches",
			},
			tooltip: {
				expand: "Open in full form",
				find_relations: "Find relations",
				cards: "View on card list",
				relations: "View on relations graph",
				geomap: "View on geo map",
				timelines: "View on timelines",
				close_button: "Close",
			},
			element_form: {
				title_create_form: "Create New Element",
				label: {
					name: "Name",
					other_names: "Other Names",
					description: "Description",
					image: "Cover",
					tags: "Tags",
					dates: "Dates",
					start_date: "Start Date",
					end_date: "End Date",
					period: "Period",
					geo: "Geo",
					location: "Location",
					no_locations: "No locations",
					coordinates: "Coordinates",
					relations_table: {
						head_column: "Y/N",
						element_name: "Element",
						link: "Link",
						dates: "Dates",
						tags: "Tags",
						source: "source",
						target: "target",
					},
					additional_source: "Additional Source",
				},
				helper: {
					link_edit_title: "Edit link",
					link_edit_text: "What is the link between these elements?",
					no_sources_text: "You don't have any sources yet",
					add_source: "Click '+' to add the new source",
					coordinates_format: "Lat,Long → xxx.xxxx, xxx.xxxx",
					tag_create: "Press enter to validate your tag",
					other_name_create: "Press enter to validate your other name",
					map_data_color: "Choose a color for this element on the main GeoMap View",
				},
				error: {
					name_required: "Name is required",
					date_format: "Invalid Date or Format (DD/MM/YYYY)",
					date_limit: "Date must be between 4500BC & 9999AC",
					date_chronology: "End Date must be later than Start Date",
					coordinates_format:
						"Coordinates must respect the following format: Lat,Long → xxx.xxxx, xxx.xxxx",
					coordinates_limits:
						"Latitude must be between -90 & +90, Longitude must be between -180 & +180",
				},
				no_result: {
					no_search_results: "No results from wiki",
					no_relations_results: "No relations",
				},
				cancel_button: "Cancel",
				create_button: "Create",
				create_and_open_button: "Create and open",
				search_wiki_button: "Search with Wiki",
				edit_button: "Save Changes",
				back_button: "Back",
				create_success_snackbar: "Element Created!",
				create_failure_snackbar: "Error while creating the element",
				edit_success_snackbar: "Element Edited!",
				edit_failure_snackbar: "Error while editing the element",
				edit_link_button: "Confirm",
				info_button: "Info",
				geocode_button: "Geocode your location into coordinates",
			},
		},
		element_edit: {
			page_title: "Edit Element: ",
			breadcrumb: {
				heading: "Edit Element",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Context",
				level_4: "Edit Element",
			},
			new_element_button: "New Element",
		},
		element_view: {
			page_title: "View Element: ",
			breadcrumb: {
				heading: "View Element",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Context",
				level_4: "View Element",
			},
			edit_element_button: "Edit Element",
		},
		analysis: {
			page_title: "Analysis",
			breadcrumb: {
				heading: "Analysis",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Analysis",
			},
			analysis: "Analysis",
			narratives: "Narratives",
			narratives_explanation: "Present actors perspectives through narratives",
		},
		analysis_narratives: {
			page_title: "Narratives",
			breadcrumb: {
				heading: "Narratives",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Analysis",
				level_4: "Narratives",
			},
			new_narrative_button: "New Narrative",
			empty: {
				text_1: "You don't have any narrative yet",
				text_2: "Narratives are used to present the story of an actor within your context.",
				text_3: "Let's create your first Narrative.",
				button: "Create a Narrative",
			},
			no_narratives: "There are no narratives to show",
			action: {
				edit: "Edit",
				cancel: "Cancel",
				delete: "Delete",
				delete_confirm_title: "Delete Narrative",
				delete_confirm_text: "Are you sure want to delete this Narrative? all data will be lost!",
				delete_button: "Delete",
			},
			delete_success_snackbar: "Narrative deleted",
			delete_failure_snackbar: "Error while deleting the Narrative",
		},
		narrative_create: {
			page_title: "Create a narrative",
			breadcrumb: {
				heading: "Create Narrative",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Analysis",
				level_4: "Create Narrative",
			},
			new_narrative_form: {
				label: {
					intro: "Introduction",
					name: "Name",
					introText: "Presentation",
					image: "Picture",
					chapters: "Chapters",
					chapter_title: "Title",
					chapter_image: "Picture",
					chapter_text: "The Story",
					chapter_elements: "The associated elements",
					element_checked: "Y/N",
					element_name: "Name",
					element_dates: "Dates",
					element_tags: "Tags",
				},
				error: {
					name_required: "Name is required",
					chapter_title_required: "Title is required",
					chapter_text_required: "The story is required",
				},
				helper: {
					form_helper: "Show the perspective through an engaging narrative",
					name: "Who is the narrator?",
					intro: "Explain briefly what the narrative is about",
					chapters: "Craft the story into chapters with key elements",
					add_chapter: "Start by creating the first chapter",
					chapter_title: "What is this chapter called?",
					chapter_text: "Explain the story you want to tell in this chapter",
				},
				action: {
					edit_chapter: "Edit",
					delete_chapter: "Delete",
					delete_confirm_title: "Delete Chapter",
					delete_confirm_text: "Are you sure want to delete this chapter?",
					delete_button: "Delete",
					cancel_button: "Cancel",
				},
				new_chapter_button: "New Chapter",
				cancel_button: "Cancel",
				create_button: "Create",
				edit_button: "Edit",
				create_success_snackbar: "Narrative Created!",
				create_failure_snackbar: "Error while creating the narrative",
				edit_success_snackbar: "Narrative Edited!",
				edit_failure_snackbar: "Error while editing the narrative",
				chapter_form: {
					new_title: "New Chapter",
					edit_title: "Edit Chapter",
					cancel_button: "Cancel",
					add_chapter_button: "Add Chapter",
					edit_chapter_button: "Edit Chapter",
				},
			},
		},
		narrative_edit: {
			page_title: "Edit Narrative",
			breadcrumb: {
				heading: "Edit Narrative",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Analysis",
				level_4: "Edit Narrative",
			},
			new_narrative_button: "New Narrative",
		},
		narrative_view: {
			page_title: "View Narrative",
			breadcrumb: {
				heading: "View Narrative",
				level_1: "All Workspaces",
				level_2: "Workspace",
				level_3: "Analysis",
				level_4: "View Narrative",
			},
			edit_narrative_button: "Edit Narrative",
		},
	},
	// COMMON
	common: {
		basic: {
			element: "element",
			element_plural: "elements",
			relation: "relation",
			relation_plural: "relations",
			narrative: "narrative",
			narrative_plural: "narratives",
			member: "member",
			member_plural: "members",
		},
		workspace_right: {
			personal: "Personal",
			owner: "Owner",
			admin: "Administrator",
			write: "Contributor",
			read: "Subscriber",
			public: "Public",
		},
		sort: {
			sort_by: "Sort By:",
			lastUpdated: "Last Updated",
			name: "Name",
      latest: "Latest",
      oldest: "Oldest",
			nameAsc: "Name: A-Z",
			nameDesc: "Name: Z-A",
			dateAsc: "Date: Old-New",
			dateDesc: "Date: New-Old",
		},
		filter: {
			search: "Search...",
			filter_by_name: "Filter by name...",
			filters: "Filters",
			clear: "Clear Filter",
			all: "All",
			tags: "Tags",
			period: "Period",
			related: "Related",
			unrelated: "Not Related",
		},
	},
};

export default en;
