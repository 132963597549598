// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// icons
import {
	// IconCards,
	// IconList,
	IconGeoMap,
	IconRelations,
	IconTimelines,
	IconNarratives,
	IconWSMembers,
	IconWSInfo,
	IconElement,
} from "src/icones/icones";

// ----------------------------------------------------------------------

const navConfig = [
	// ANALYSIS
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.analysis.subheader",
		items: [
			{
				title: "dashboard.nav.analysis.narratives",
				path: PATH_DASHBOARD.analysis.narratives(0),
				icon: <IconNarratives />,
			},
		],
	},

	// VIEWS
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.views.subheader",
		items: [
			{
				title: "dashboard.nav.views.geo_map",
				path: PATH_DASHBOARD.views.geomap(0),
				icon: <IconGeoMap />,
			},
			{
				title: "dashboard.nav.views.timelines",
				path: PATH_DASHBOARD.views.timelines(0),
				icon: <IconTimelines />,
			},
		],
	},

	// ELEMENTS
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.context.subheader",
		items: [
			{
				title: "dashboard.nav.context.elements",
				path: PATH_DASHBOARD.context.elements(0),
				icon: <IconElement />,
			},
			{
				title: "dashboard.nav.context.relations",
				path: PATH_DASHBOARD.context.relations(0),
				icon: <IconRelations />,
			},
		],
	},

	// WORKSPACE
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.workspace.subheader",
		items: [
			{
				title: "dashboard.nav.workspace.information",
				path: PATH_DASHBOARD.workspace.information(0),
				icon: <IconWSInfo />,
			},
			{
				title: "dashboard.nav.workspace.members",
				path: PATH_DASHBOARD.workspace.members(0),
				icon: <IconWSMembers />,
			},
		],
	},
];

export default navConfig;
