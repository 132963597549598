import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_ELEMENTS, API_ELEMENTS_COUNT } from "./apiPaths";
// errors
import { ERROR_UNAUTHORIZED, ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import { IElement, IElementShort, IRelation } from "src/@types/element";
import { convertDataToElement, convertDataToElementShort } from "./conversions";
import { MAX_LENGTH } from "src/config-global";

// ------------------ GET ---------------------------------

export const apiGetElement = (elementId: number): Promise<IElement> => {
	return axios
		.get(API_ELEMENTS + `/${elementId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) return convertDataToElement(response.data);
			else throw ERROR_WRONG_RESPONSE;
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiGetAllElements = (workspaceId: number): Promise<IElementShort[]> => {
	return axios
		.get(API_ELEMENTS + `/getAll/${workspaceId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let elements: IElementShort[] = [];
				for (let e in response.data) elements.push(convertDataToElementShort(response.data[e]));
				return elements;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// -------------------- CREATE / UPDATE -------------------------------

export const apiCreateElement = (
	workspaceId: number,
	data: Partial<IElement>
): Promise<IElement> => {
	// FORMDATA
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	if (!!data.name) formData.append("name", data.name.slice(0, MAX_LENGTH.ELEMENT_NAME));
	formData.append("description", data.description ?? "");
	// image
	if (!!data.image) {
		let image = data.image;
		if (image instanceof File) formData.append("image", data.image);
		else formData.append("image_url", data.image);
	}
	// other names
	if (!!data.otherNames && data.otherNames.length > 0)
		data.otherNames.forEach((otherName) => {
			formData.append("other_names[]", otherName.slice(0, MAX_LENGTH.ELEMENT_NAME));
		});
	else formData.append("other_names[]", "no-other-names");
	// tags
	if (!!data.tags && data.tags.length > 0)
		data.tags.forEach((tag) => {
			formData.append("tags[]", tag.slice(0, MAX_LENGTH.ELEMENT_TAG));
		});
	else formData.append("tags[]", "no-tags");
	// sources
	if (!!data.sources && data.sources.length > 0)
		data.sources.forEach((source) => {
			formData.append("sources[]", source);
		});
	else formData.append("sources[]", "no-sources");
	// dates
	if (!!data.dates) {
		if (!!data.dates.startDate) {
			formData.append("start_date", data.dates.startDate.dateStr);
			formData.append("start_date_precision", data.dates.startDate.precision);
		}
		if (!!data.dates.endDate && data.dates.period) {
			formData.append("end_date", data.dates.endDate.dateStr);
			formData.append("end_date_precision", data.dates.endDate.precision);
		}
		if (data.dates.period) formData.append("period", "true");
		else formData.append("period", "false");
	}
	// location
	formData.append("location", JSON.stringify(data.location) ?? "");
	if (data.mapData) formData.append("map_data", JSON.stringify(data.mapData));
	// wiki
	if (!!data.wikiId) formData.append("wiki_id", data.wikiId);
	// AXIOS
	return axios
		.post(API_ELEMENTS, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToElement(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

export const apiUpdateElement = (
	workspaceId: number,
	elementId: number,
	data: Partial<IElement>
): Promise<IElement> => {
	// FORMDATA
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	formData.append("name", data.name ?? "");
	formData.append("description", data.description ?? "");
	// image
	if (!!data.image) {
		const image = data.image;
		if (image instanceof File) formData.append("image", data.image);
	}
	// other names
	if (!!data.otherNames && data.otherNames.length > 0)
		data.otherNames.forEach((otherName) => {
			formData.append("other_names[]", otherName);
		});
	else formData.append("other_names[]", "no-other-names");
	// tags
	if (!!data.tags && data.tags.length > 0)
		data.tags.forEach((tag) => {
			formData.append("tags[]", tag);
		});
	else formData.append("tags[]", "no-tags");
	// sources
	if (!!data.sources && data.sources.length > 0)
		data.sources.forEach((source) => {
			formData.append("sources[]", source);
		});
	else formData.append("sources[]", "no-sources");
	// date
	if (!!data.dates) {
		if (!!data.dates.startDate) {
			formData.append("start_date", data.dates.startDate.dateStr);
			formData.append("start_date_precision", data.dates.startDate.precision);
		} else {
			formData.append("start_date", "+0000/00/00");
			formData.append("start_date_precision", "DMY");
		}
		if (!!data.dates.endDate && data.dates.period) {
			formData.append("end_date", data.dates.endDate.dateStr);
			formData.append("end_date_precision", data.dates.endDate.precision);
		} else {
			formData.append("end_date", "+0000/00/00");
			formData.append("end_date_precision", "DMY");
		}
		if (data.dates.period) formData.append("period", "true");
		else formData.append("period", "false");
	}
	// location
	formData.append("location", JSON.stringify(data.location) ?? "");
	if (data.mapData) formData.append("map_data", JSON.stringify(data.mapData));
	// relations
	if (!!data.relations)
		data.relations.forEach((relation: IRelation) => {
			formData.append(
				`relations[]`,
				JSON.stringify({
					origin_id: relation.originId,
					link: relation.link,
					destination_id: relation.destinationId,
				})
			);
		});
	// wiki
	if (!!data.wikiId) formData.append("wiki_id", data.wikiId);
	// AXIOS
	return axios
		.patch(API_ELEMENTS + `/${elementId}`, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToElement(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error("API error: " + error.message);
			// we don't know :'(
			throw error;
		});
};

// -------------------- DELETE -------------------------------

export const apiDeleteElement = (id: number) => {
	// AXIOS
	return axios.delete(API_ELEMENTS + `/${id}`, { withCredentials: true }).catch((error) => {
		console.error(error);
		// we don't know :'(
		throw error.message;
	});
};

// -------------------- COUNT -------------------------------

/**
 * ADMIN Get the count of all elements
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminElementCount = (): Promise<number> => {
	return axios
		.get(API_ELEMENTS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};
