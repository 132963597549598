import { useState } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useLocation } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "src/routes/paths";
// auth
import { useAuthContext } from "src/auth/useAuthContext";
// components
import Iconify from "src/components/iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";
// api
import {
	apiAnalyticsTrack,
	analyticsEvents,
	ERROR_INVALID_CREDENTIALS,
	ERROR_USER_NOT_ACTIVATED,
} from "src/api";
// locales
import { useLocales } from "src/locales";
// config
import { MAX_LENGTH } from "src/config-global";

// ----------------------------------------------------------------------

type FormValuesProps = {
	email: string;
	password: string;
	afterSubmit?: string;
};

export default function AuthLoginForm() {
	// navigate
	const location = useLocation();
	// auth
	const { login, user, getUserFromStorage } = useAuthContext();
	// locales
	const { translate } = useLocales();

	const [showPassword, setShowPassword] = useState(false);
	const [showActivateLink, setShowActivateLink] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.required(`${translate("auth.login.form.error.email_required")}`)
			.email(`${translate("auth.login.form.error.email_valid")}`),
		password: Yup.string().required(`${translate("auth.login.form.error.password_required")}`),
	});

	const defaultValues = {
		email: "",
		password: "",
	};

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		setError,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = methods;

	const onSubmit = async (data: FormValuesProps) => {
		setShowActivateLink(false);
		try {
			await login(data.email, data.password);
			const idUser = getUserFromStorage();
			apiAnalyticsTrack(
				idUser ? idUser.id : null,
				analyticsEvents.login,
				location.pathname,
				"Login Form"
			);
		} catch (error) {
			// reset();
			if (error === ERROR_USER_NOT_ACTIVATED) {
				setError("afterSubmit", {
					...error,
					message: translate("auth.login.form.error.user_not_activated"),
				});
				setShowActivateLink(true);
			} else if (error === ERROR_INVALID_CREDENTIALS)
				setError("afterSubmit", {
					...error,
					message: translate("auth.login.form.error.invalid_credentials"),
				});
			else
				setError("afterSubmit", {
					...error,
					message: error.message || error,
				});
			// analytics
			apiAnalyticsTrack(
				user ? user.id : null,
				analyticsEvents.loginFailed,
				location.pathname,
				"Login Form"
			);
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

				<RHFTextField
					name="email"
					label={`${translate("auth.login.form.label.email")}`}
					inputProps={{ maxLength: MAX_LENGTH.EMAIL }}
				/>

				<RHFTextField
					name="password"
					label={`${translate("auth.login.form.label.password")}`}
					type={showPassword ? "text" : "password"}
					inputProps={{ maxLength: MAX_LENGTH.PASSWORD }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			{showActivateLink ? (
				<Stack direction="row" justifyContent="space-evenly" sx={{ my: 2 }}>
					<Link
						component={RouterLink}
						to={PATH_AUTH.verify}
						variant="body2"
						color="inherit"
						underline="always"
					>
						{`${translate("auth.login.activate_link")}`}
					</Link>
					<Link
						component={RouterLink}
						to={PATH_AUTH.resetPassword}
						variant="body2"
						color="inherit"
						underline="always"
					>
						{`${translate("auth.login.forgot_password_link")}`}
					</Link>
				</Stack>
			) : (
				<Stack alignItems="flex-end" sx={{ my: 2 }}>
					<Link
						component={RouterLink}
						to={PATH_AUTH.resetPassword}
						variant="body2"
						color="inherit"
						underline="always"
					>
						{`${translate("auth.login.forgot_password_link")}`}
					</Link>
				</Stack>
			)}

			<LoadingButton
				fullWidth
				color="inherit"
				size="large"
				type="submit"
				variant="contained"
				loading={isSubmitSuccessful || isSubmitting}
				sx={{
					bgcolor: "text.primary",
					color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
					"&:hover": {
						bgcolor: "text.primary",
						color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
					},
				}}
			>
				{`${translate("auth.login.form.login_button")}`}
			</LoadingButton>
		</FormProvider>
	);
}
