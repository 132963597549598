// utils
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { fTags } from "src/utils/formatString";
// types
import { IElement, IElementShort, IGraphPosition, IRelation } from "src/@types/element";
import { IElementFilters, INarrativeFilters, IPeriod, ISearchListItem } from "src/@types/filter-search";
import { INarrative, INarrativeChapter, INarrativeShort } from "src/@types/narrative";
import { IContextState } from "src/@types/state";
// api
import {
	apiCreateElement,
	apiDeleteElement,
	apiEditWorkspace,
	apiGetAllElements,
	apiGetElement,
	apiGetWorkspace,
	apiUpdateElement,
	apiCreateOrUpdateRelation,
	apiGetAllRelations,
	apiUpdatePosition,
	apiDeleteRelation,
	apiGetAccessRights,
	apiGetAllNarratives,
	apiGetNarrative,
	apiCreateNarrativeAndChapters,
	apiDeleteNarrative,
	apiUpdateNarrativeAndChapters,
	apiGetAllPositions,
	apiUpdateAllPositions,
} from "src/api";
// default values
import { applyElementFilter, applyNarrativeFilter, defaultElementFilters, defaultElementSort, defaultNarrativeFilters, defaultNarrativeSort } from "src/utils/filters";

// ----------------------------------------------------------------------

const initialState: IContextState = {
	isLoading: false, // to know if api process is ongoing
	error: null, // error on api process
	workspace: null, // workspace data (name & right of the current user)
	members: [], // workspace members (name & rights)
	elements: [], // IElementShort list for all elements in the current workspace
	relations: [], // IRelation list for all relations in the current workspace
	positions: [], // IGraphPosition list for all elements in the current workspace
	narratives: [], // INarrativeShort list for all narratives in the current workspace
	element: null, // current IElement under view / edition
	narrative: null, // current INarrative under view / edition
	searchList: [], // ISearchListItem list of all elements, tasks, analysis in the current workspace
	tagList: [], // all tags (string) of the current workspace
	periodLimits: { min: 0, max: 0 }, // min & max period in the current workspace
	elementFilters: defaultElementFilters, // filters applied on the element list
	elementSort: defaultElementSort, // sort applied on the element list
	displayedElements: [], // list of elements to display with filters	
	narrativeFilters: defaultNarrativeFilters, // filters applied on the narrative list
	narrativeSort: defaultNarrativeSort, // sort applied on the narrative list
	displayedNarratives: [], // list of narratives to display with filters
};

const slice = createSlice({
	name: "context",
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
			state.error = null;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		// GET WORKSPACE
		getWorkspaceSuccess(state, action) {
			state.isLoading = false;
			// workspace
			state.workspace = action.payload.workspace;
			state.members = action.payload.members;
			// elements
			state.elements = [];
			// relations
			state.relations = [];
			// searchList
			state.searchList = [];
			// tags
			state.tagList = [];
			// period
			state.periodLimits = { min: 0, max: 0 };
		},

		// EDIT WORKSPACE
		editWorkspaceSuccess(state, action) {
			state.isLoading = false;
			// workspace
			if (state.workspace?.id === action.payload.id) state.workspace = action.payload;
		},

		// GET WORKSPACE DATA
		getWorkspaceDataSuccess(state, action) {
			state.isLoading = false;
			// workspace
			state.workspace = action.payload.workspace;
			state.members = action.payload.members;
			// ws data
			state.elements = action.payload.elements;
			state.positions = action.payload.positions;
			state.relations = action.payload.relations;
			state.narratives = action.payload.narratives;
			// utils for filters and searches
			state.searchList = findSearchItems(state.elements, state.narratives);
			state.tagList = findTags(state.elements);
			state.periodLimits = { min: findMinYear(state.elements), max: findMaxYear(state.elements) };
			// displayed elements
			state.displayedElements = applyElementFilter(
				action.payload.elements,
				state.elementFilters,
				state.elementSort
			);
		},

		// GET ELEMENTS
		getElementsSuccess(state, action) {
			state.isLoading = false;
			// elements
			state.elements = action.payload;
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
			// tags
			state.tagList = findTags(state.elements);
			// period
			state.periodLimits = { min: findMinYear(state.elements), max: findMaxYear(state.elements) };
			// displayed elements
			state.displayedElements = applyElementFilter(
				action.payload,
				state.elementFilters,
				state.elementSort
			);
		},

		// GET ELEMENT
		getElementSuccess(state, action) {
			state.isLoading = false;
			state.element = action.payload;
		},

		// CREATE ELEMENT
		createElementSuccess(state, action) {
			state.isLoading = false;
			state.elements.push(action.payload);
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
			// tags
			state.tagList = findTags(state.elements);
			// period
			state.periodLimits = { min: findMinYear(state.elements), max: findMaxYear(state.elements) };
			// displayed elements
			const elements = state.elements.concat([action.payload]);
			state.displayedElements = applyElementFilter(
				elements,
				state.elementFilters,
				state.elementSort
			);
		},

		// EDIT ELEMENT
		updateElementSuccess(state, action) {
			state.isLoading = false;
			const updatedElement = action.payload.updatedElement;
			const index = state.elements.findIndex((el) => el.id === updatedElement.id);
			if (index !== -1) state.elements[index] = updatedElement;
			state.element = { ...state.element, ...updatedElement };
			// relations
			state.relations = action.payload.relations;
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
			// tags
			state.tagList = findTags(state.elements);
			// period
			state.periodLimits = { min: findMinYear(state.elements), max: findMaxYear(state.elements) };
			// displayed elements
			state.displayedElements = applyElementFilter(
				state.elements,
				state.elementFilters,
				state.elementSort
			);
		},

		// DELETE ELEMENT
		deleteElementSuccess(state, action) {
			state.isLoading = false;
			const elements = state.elements.filter((element) => element.id !== action.payload.elementId);
			state.elements = elements;
			// relations
			state.relations = action.payload.relations;
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
			// tags
			state.tagList = findTags(state.elements);
			// period
			state.periodLimits = { min: findMinYear(state.elements), max: findMaxYear(state.elements) };
			// displayed elements
			state.displayedElements = applyElementFilter(
				elements,
				state.elementFilters,
				state.elementSort
			);
		},

		// CREATE OR UPDATE RELATION
		createOrUpdateRelationSuccess(state, action) {
			state.isLoading = false;
			// relations
			const newRelation = action.payload;
			// update existing or add new
			let index = state.relations.findIndex(
				(rel) =>
					rel.originId === newRelation.destinationId && rel.destinationId === newRelation.originId
			);
			if (index !== -1) state.relations[index] = newRelation;
			else state.relations.push(newRelation);
		},

		// DELETE RELATION
		deleteRelationSuccess(state, action) {
			state.isLoading = false;
			// relations
			const newRelation = action.payload;
			// update existing or add new
			let index = state.relations.findIndex(
				(rel) =>
					rel.originId === newRelation.originId && rel.destinationId === newRelation.destinationId
			);
			if (index !== -1) state.relations.splice(index, 1);
		},

		// GET ALL RELATIONS
		getAllRelationsSuccess(state, action) {
			state.isLoading = false;
			// relations
			state.relations = action.payload;
		},

		// GET ALL POSITIONS
		getAllPositionsSuccess(state, action) {
			state.isLoading = false;
			state.positions = action.payload;
		},

		// UPDATE ALL POSITIONS
		updateAllElementPositionsSuccess(state, action) {
			state.isLoading = false;
			state.positions = action.payload;
		},

		// UPDATE POSITION
		updateElementPositionSuccess(state, action) {
			state.isLoading = false;
			const newPosition: IGraphPosition = action.payload;
			if (state.positions.some((p) => p.id === newPosition.id)) {
				// update Existing
				const updatedPositions: IGraphPosition[] = state.positions.map((position) => {
					if (position.id === newPosition.id)
						return { id: position.id, x: newPosition.x, y: newPosition.y } as IGraphPosition;
					return position;
				});
				state.positions = updatedPositions;
			} // add new one
			else state.positions.push(newPosition);
		},

		// GET ALL NARRATIVES
		getAllNarrativesSuccess(state, action) {
			state.isLoading = false;
			// narratives
			state.narratives = action.payload;
		},

		// GET NARRATIVE
		getNarrativeSuccess(state, action) {
			state.isLoading = false;
			state.narrative = action.payload;
		},

		// CREATE NARRATIVE AND CHAPTERS
		createNarrativeAndChaptersSuccess(state, action) {
			state.isLoading = false;
			// narratives
			const createdNarrative = action.payload;

			const createdNarrativeShort: INarrativeShort = {
				id: createdNarrative.id,
				name: createdNarrative.name,
				image: createdNarrative.image,
				introText: createdNarrative.introText,
				createdAt: createdNarrative.createdAt,
				updatedAt: createdNarrative.updatedAt,
			};
			state.narratives.push(createdNarrativeShort);
			// current narrative
			state.narrative = createdNarrative;
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
			// displayed narratives
			const narratives = state.narratives.concat([action.payload]);
			state.displayedNarratives = applyNarrativeFilter(
				narratives,
				state.narrativeFilters,
				state.narrativeSort
			);
		},

		// UPDATE NARRATIVE AND CHAPTERS
		updateNarrativeAndChaptersSuccess(state, action) {
			state.isLoading = false;
			// narratives
			const updatedNarrative = action.payload;
			const updatedNarrativeShort: INarrativeShort = {
				id: updatedNarrative.id,
				name: updatedNarrative.name,
				image: updatedNarrative.image,
				introText: updatedNarrative.introText,
				createdAt: updatedNarrative.createdAt,
				updatedAt: updatedNarrative.updatedAt,
			};
			const index = state.narratives.findIndex((na) => na.id === updatedNarrative.id);
			if (index !== -1) state.narratives[index] = updatedNarrativeShort;
			// current narrative
			state.narrative = updatedNarrative;
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
		},

		// DELETE NARRATIVE
		deleteNarrativeSuccess(state, action) {
			state.isLoading = false;
			// narratives
			const narrativeId = action.payload;
			state.narratives = state.narratives.filter((narrative) => narrative.id !== narrativeId);
			// searchList
			state.searchList = findSearchItems(state.elements, state.narratives);
		},

		// FILTER ELEMENTS BY NAME
		updateElementFilterNameSuccess(state, action) {
			state.isLoading = false;
			// update filter
			const name = action.payload;
			const filters: IElementFilters = {
				...state.elementFilters,
				name: name,
			};
			state.elementFilters = filters;
			// apply filters+sort & update filtered elements
			state.displayedElements = applyElementFilter(state.elements, filters, state.elementSort);
		},

		// FILTER ELEMENTS BY TAGS
		updateElementFilterTagsSuccess(state, action) {
			state.isLoading = false;
			// update filter
			const tags = action.payload;
			const filters: IElementFilters = {
				...state.elementFilters,
				tags: tags,
			};
			state.elementFilters = filters;
			// apply filters+sort & update filtered elements
			state.displayedElements = applyElementFilter(state.elements, filters, state.elementSort);
		},

		// FILTER ELEMENTS BY PERIOD
		updateElementFilterPeriodSuccess(state, action) {
			state.isLoading = false;
			// update filter
			const period = action.payload;
			const filters: IElementFilters = {
				...state.elementFilters,
				period: period,
			};
			state.elementFilters = filters;
			// apply filters+sort & update filtered elements
			state.displayedElements = applyElementFilter(state.elements, filters, state.elementSort);
		},

		// RESET FILTER ELEMENTS
		resetElementFilterSuccess(state, action) {
			state.isLoading = false;
			// reset filter
			state.elementFilters = defaultElementFilters;
			// apply filters+sort & update filtered elements
			state.displayedElements = applyElementFilter(
				state.elements,
				defaultElementFilters,
				state.elementSort
			);
		},

		// SORT ELEMENTS
		updateElementSortSuccess(state, action) {
			state.isLoading = false;
			// update sort
			const sort = action.payload;
			state.elementSort = sort;
			// apply filters+sort & update filtered elements
			state.displayedElements = applyElementFilter(state.elements, state.elementFilters, sort);
		},

		// FILTER NARRATIVES BY NAME
		updateNarrativeFilterNameSuccess(state, action) {
			state.isLoading = false;
			// update filter
			const name = action.payload;
			const filters: INarrativeFilters = {
				...state.narrativeFilters,
				name: name,
			};
			state.narrativeFilters = filters;
			// apply filters+sort & update filtered narratives
			state.displayedNarratives = applyNarrativeFilter(state.narratives, filters, state.narrativeSort);
		},

		// SORT NARRATIVES
		updateNarrativeSortSuccess(state, action) {
			state.isLoading = false;
			// update sort
			const sort = action.payload;
			state.narrativeSort = sort;
			// apply filters+sort & update filtered narratives
			state.displayedNarratives = applyNarrativeFilter(state.narratives, state.narrativeFilters, sort);
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// WORKSPACES
// ----------------------------------------------------------------------

export function getWorkspace(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const workspace = await apiGetWorkspace(workspaceId);
			const members = await apiGetAccessRights(workspaceId);
			dispatch(slice.actions.getWorkspaceSuccess({ workspace, members }));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------

export function editWorkspace(
	workspaceId: number,
	name: string,
	description: string,
	image: File | string | null
) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const workspace = await apiEditWorkspace(workspaceId, name, description, image);
			dispatch(slice.actions.editWorkspaceSuccess(workspace));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------

export function getWorkspaceData(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const workspace = await apiGetWorkspace(workspaceId);
			const members = await apiGetAccessRights(workspaceId);
			const elements = await apiGetAllElements(workspaceId);
			const positions = await apiGetAllPositions(workspaceId);
			const relations = await apiGetAllRelations(workspaceId);
			const narratives = await apiGetAllNarratives(workspaceId);
			dispatch(
				slice.actions.getWorkspaceDataSuccess({
					workspace: workspace,
					members: members,
					elements: elements,
					positions: positions,
					relations: relations,
					narratives: narratives,
				})
			);
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// ELEMENTS
// ----------------------------------------------------------------------

export function getElements(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const elements = await apiGetAllElements(workspaceId);
			dispatch(slice.actions.getElementsSuccess(elements));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------

export function getElement(elementId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const element = await apiGetElement(elementId);
			dispatch(slice.actions.getElementSuccess(element));
			return null;
		} catch (error) {
			console.error(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------

export function createElement(workspaceId: number, element: Partial<IElement>) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const createdElement = await apiCreateElement(workspaceId, element);
			dispatch(slice.actions.createElementSuccess(createdElement));
			return { result: createdElement, error: null };
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return { result: null, error: error };
		}
	};
}

// ----------------------------------------------------------------------

export function updateElement(workspaceId: number, elementId: number, element: Partial<IElement>) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const updatedElement = await apiUpdateElement(workspaceId, elementId, element);
			const relations = await apiGetAllRelations(workspaceId);
			dispatch(
				slice.actions.updateElementSuccess({ updatedElement: updatedElement, relations: relations })
			);
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------

export function deleteElement(workspaceId: number, elementId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await apiDeleteElement(elementId);
			const relations = await apiGetAllRelations(workspaceId);
			dispatch(slice.actions.deleteElementSuccess({ elementId: elementId, relations: relations }));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// RELATIONS
// ----------------------------------------------------------------------

export function createOrUpdateRelation(relation: IRelation) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const createdRelation = await apiCreateOrUpdateRelation(relation);
			dispatch(slice.actions.createOrUpdateRelationSuccess(createdRelation));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function deleteRelation(originId: number, destinationId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await apiDeleteRelation(originId, destinationId);
			dispatch(slice.actions.deleteRelationSuccess({ originId, destinationId }));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function getRelationData(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const relations = await apiGetAllRelations(workspaceId);
			dispatch(slice.actions.getAllRelationsSuccess(relations));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// POSITIONS
// ----------------------------------------------------------------------

export function getAllPositions(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const positions = await apiGetAllPositions(workspaceId);
			dispatch(slice.actions.getAllPositionsSuccess(positions));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateElementPosition(id: number, x: number, y: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await apiUpdatePosition({ id, x, y } as IGraphPosition);
			dispatch(slice.actions.updateElementPositionSuccess({ id, x, y }));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateAllElementPositions(positions: IGraphPosition[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await apiUpdateAllPositions(positions);
			dispatch(slice.actions.updateAllElementPositionsSuccess(positions));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// NARRATIVES
// ----------------------------------------------------------------------

export function getAllNarratives(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const narratives = await apiGetAllNarratives(workspaceId);
			dispatch(slice.actions.getAllNarrativesSuccess(narratives));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function getNarrative(narrativeId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const narrative = await apiGetNarrative(narrativeId);
			dispatch(slice.actions.getNarrativeSuccess(narrative));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function createNarrativeAndChapters(
	workspaceId: number,
	narrative: Partial<INarrative>,
	chapters: Partial<INarrativeChapter>[]
) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const createdNarrative = await apiCreateNarrativeAndChapters(
				workspaceId,
				narrative,
				chapters
			);
			dispatch(slice.actions.createNarrativeAndChaptersSuccess(createdNarrative));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateNarrativeAndChapters(narrative: INarrative, chaptersToDelete: INarrativeChapter[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const updatedNarrative = await apiUpdateNarrativeAndChapters(narrative, chaptersToDelete);
			dispatch(slice.actions.updateNarrativeAndChaptersSuccess(updatedNarrative));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function deleteNarrative(narrativeId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await apiDeleteNarrative(narrativeId);
			dispatch(slice.actions.deleteNarrativeSuccess(narrativeId));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// FILTER ELEMENTS
// ----------------------------------------------------------------------

export function updateElementFilterName(name: string) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateElementFilterNameSuccess(name));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateElementFilterTags(tags: string[]) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateElementFilterTagsSuccess(tags));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateElementFilterPeriod(period: IPeriod) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateElementFilterPeriodSuccess(period));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function resetElementFilter() {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.resetElementFilterSuccess(null));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateElementSort(sort: string) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateElementSortSuccess(sort));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}


// ----------------------------------------------------------------------
// FILTER ELEMENTS
// ----------------------------------------------------------------------

export function updateNarrativeFilterName(name: string) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateNarrativeFilterNameSuccess(name));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

export function updateNarrativeSort(sort: string) {
	return (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.updateNarrativeSortSuccess(sort));
			return null;
		} catch (error) {
			console.log(error);
			dispatch(slice.actions.hasError(error));
			return error;
		}
	};
}

// ----------------------------------------------------------------------
// UTILS
// ----------------------------------------------------------------------

function findSearchItems(
	elements: IElementShort[],
	narratives: INarrativeShort[]
): ISearchListItem[] {
	const elementList = elements.map((element) => {
		return {
			type: "element",
			id: element.id,
			name: element.name,
			tags: fTags(element.tags),
		} as ISearchListItem;
	});
	const narrativeList = narratives.map((narrative) => {
		return {
			type: "narrative",
			id: narrative.id,
			name: narrative.name,
			tags: [],
		} as ISearchListItem;
	});
	const searchList = elementList.concat(narrativeList);
	return sortBy(searchList, ["type", "name"]);
}

function findMinYear(elements: IElementShort[]): number {
	let yearMin: number = 0;
	for (let i = 0; i < elements.length; i++) {
		const elem = elements[i];
		const year = !!elem.dates.startDate ? elem.dates.startDate.date.getFullYear() : null;
		if (year !== null) if (yearMin === 0 || year < yearMin) yearMin = year;
	}
	return yearMin;
}

function findMaxYear(elements: IElementShort[]): number {
	let yearMax: number = 0;
	for (let i = 0; i < elements.length; i++) {
		const elem = elements[i];
		const year = !!elem.dates.startDate ? elem.dates.startDate.date.getFullYear() : null;
		if (year !== null) if (yearMax === 0 || year > yearMax) yearMax = year;
	}
	return yearMax;
}

function findTags(elements: IElementShort[]): string[] {
	let distinctTags: string[] = [];
	let allTags: string[] = [];
	if (!!elements) {
		elements.forEach((element) => {
			allTags = allTags.concat(fTags(element.tags));
		});
		const tagSet = new Set<string>(allTags);
		distinctTags = Array.from(tagSet);
	}
	return distinctTags.sort();
}
