import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_INQUIRIES } from "./apiPaths";


export const apiSendInquiry = (name: string, email: string, subject: string, message: string) => {
	return axios
		.post(API_INQUIRIES, {
			name,
			email,
			subject,
			message,
		})
		.catch((error) => {
			console.error(error);
      throw error.message;
		});
};
