import { m } from 'framer-motion';
// @mui
import { Container, Typography, Button } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type SuperAdminGuardProp = {
  children: React.ReactNode;
};

export default function SuperAdminGuard({ children }: SuperAdminGuardProp) {
  // Logic here to get current user admin role
  const { user } = useAuthContext();
  const currentRole = user?.adminRole; // admin;

  if (!currentRole || currentRole !== 'SuperAdmin' ) {
    return  (
      <Container component={MotionContainer} sx={{ textAlign: 'center', mt:4 }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <m.div variants={varBounce().in}>
          <Button color='error' variant="contained" href="/">
            Back to Homepage
          </Button>
        </m.div>
      </Container>
    );
  }

  return <> {children} </>;
}
