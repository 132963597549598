import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @mui
import { Stack, Box } from "@mui/material";
// config
import { NAV } from "src/config-global";
// utils
import { hideScrollbarX } from "src/utils/cssStyles";
// components
import Logo from "src/components/logo";
import { NavSectionMini } from "src/components/nav-section";
import NavWorkspace from "./NavWorkspace";
//
import navConfig from "./config-navigation";
import NavToggleButton from "./NavToggleButton";
// api
import { apiGetMyInfo } from "src/api";
// redux
import { useSelector } from "src/redux/store";
// auth
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

export default function NavMini() {
	// location
	const { wsId } = useParams();
	// redux
	const { workspace } = useSelector((state) => state.context);
	// user
	const { user } = useAuthContext();

	// state
	const [udpatedNavConfig, setUpdatedNavConfig] = useState(navConfig);

	// dynamics nav to replace ID in the workspaces
	const getNavData = async () => {
		// Get the WS ID
		let ws = wsId as string | null | undefined; // default scenario
		if (ws === undefined || ws === null || ws === "null") {
			// check the store
			if (!!workspace && !!workspace.id) ws = workspace.id.toString();
			// check context
			else if (!!user && !!user.currentWorkspaceId) ws = user.currentWorkspaceId.toString();
			else {
				// otherwise, get CurrentWS from storage
				const storedUser = localStorage.getItem("user");
				if (!!storedUser) {
					const userjson = JSON.parse(storedUser);
					ws = userjson.currentWorkspaceId ?? userjson.personalWorkspaceId;
				} else {
					// last resort: call API
					const fetchedUser = await apiGetMyInfo();
					const wsapi = fetchedUser.currentWorkspaceId ?? fetchedUser.personalWorkspaceId;
					ws = !!wsapi ? wsapi.toString() : null;
				}
			}
		}
		const newNavConfig = navConfig.map((section) => {
			const newItems = section.items.map((element) => {
				return {
					...element,
					path: element.path.replace("workspace/0/", `workspace/${ws}/`),
				};
			});
			return { ...section, items: newItems };
		});
		setUpdatedNavConfig(newNavConfig);
	};

	useEffect(() => {
		getNavData();
		// eslint-disable-next-line
	}, [wsId, workspace]);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV.W_DASHBOARD_MINI },
			}}
		>
			<NavToggleButton
				sx={{
					top: 22,
					left: NAV.W_DASHBOARD_MINI - 12,
				}}
			/>

			<Stack
				sx={{
					pb: 2,
					height: 1,
					position: "fixed",
					width: NAV.W_DASHBOARD_MINI,
					borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
					...hideScrollbarX,
				}}
			>
				<Logo sx={{ mx: "auto", my: 2 }} />

				<NavWorkspace />

				<NavSectionMini data={udpatedNavConfig} />
			</Stack>
		</Box>
	);
}
