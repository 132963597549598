import { useState, memo, useEffect } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
	Box,
	Slide,
	Popper,
	InputBase,
	PopperProps,
	Autocomplete,
	InputAdornment,
	ClickAwayListener,
} from "@mui/material";
// utils
import { bgBlur } from "src/utils/cssStyles";
// components
import { IconButtonAnimate } from "src/components/animate";
import SearchNotFound from "src/components/search-not-found";
// icons
import { IconSearch } from "src/icones/icones";
// redux
import { useSelector } from "src/redux/store";
import { WORKSPACE_ACCESS_RIGHT } from "src/@types/workspace";
import { PATH_DASHBOARD } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const StyledSearchbar = styled("div")(({ theme }) => ({
	...bgBlur({ color: theme.palette.background.default }),
	top: 0,
	left: 0,
	zIndex: 99,
	width: "100%",
	display: "flex",
	position: "absolute",
	alignItems: "center",
	height: APPBAR_MOBILE,
	padding: theme.spacing(0, 3),
	boxShadow: theme.customShadows.z8,
	[theme.breakpoints.up("md")]: {
		height: APPBAR_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

const StyledPopper = styled((props: PopperProps) => <Popper {...props} />)(({ theme }) => ({
	left: `8px !important`,
	top: `${APPBAR_MOBILE + 8}px !important`,
	width: "calc(100% - 16px) !important",
	transform: "none !important",
	[theme.breakpoints.up("md")]: {
		top: `${APPBAR_DESKTOP + 8}px !important`,
	},
	"& .MuiAutocomplete-paper": {
		padding: theme.spacing(1, 0),
	},
	"& .MuiListSubheader-root": {
		"&.MuiAutocomplete-groupLabel": {
			...bgBlur({ color: theme.palette.background.neutral }),
			...theme.typography.overline,
			top: 0,
			margin: 0,
			lineHeight: "48px",
			borderRadius: theme.shape.borderRadius,
		},
	},
	"& .MuiAutocomplete-listbox": {
		"& .MuiAutocomplete-option": {
			padding: theme.spacing(0.5, 2),
			margin: 0,
			display: "block",
			border: `dashed 1px transparent`,
			borderBottomColor: theme.palette.divider,
			"&:last-of-type": {
				borderBottomColor: "transparent",
			},
			"&:hover": {
				borderColor: theme.palette.primary.main,
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
			},
		},
	},
}));

// ----------------------------------------------------------------------

function Searchbar() {
	// locales
	const { translate } = useLocales();
	// navigation
	const navigate = useNavigate();
	const { pathname } = useLocation();
	
	// ---------------- STATE & DATA ----------------
	
	// redux
	const { workspace, searchList } = useSelector((state) => state.context);

	const [open, setOpen] = useState(false);

	const [searchQuery, setSearchQuery] = useState("");

	// ---------------- Close on page change ----------------

	useEffect(() => {
		if (open) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	// ---------------- HANDLE ----------------

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleGotoElement = (type: string, id: number) => {
		if (workspace) {
			if (type === "element") {
				const linkTo =
					workspace.rightLevel >= WORKSPACE_ACCESS_RIGHT.WRITE
						? PATH_DASHBOARD.element.edit(workspace.id, id)
						: PATH_DASHBOARD.element.view(workspace.id, id);
				navigate(linkTo);
			}
		}
		handleClose();
	};

	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			// extract type & id from the searchQuery
			const type = searchQuery.slice(
				searchQuery.lastIndexOf("(") + 1,
				searchQuery.lastIndexOf(":")
			);
			const id = searchQuery.slice(searchQuery.lastIndexOf(":") + 1, searchQuery.lastIndexOf(")"));
			handleGotoElement(type, parseInt(id));
		}
	};

	// ---------------- RENDER ----------------

	if (searchList.length === 0) return <></>;

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				{!open && (
					<IconButtonAnimate onClick={handleOpen}>
						<IconSearch />
					</IconButtonAnimate>
				)}

				<Slide direction="down" in={open} mountOnEnter unmountOnExit>
					<StyledSearchbar>
						<Autocomplete
							sx={{ width: 1, height: 1 }}
							autoHighlight
							disablePortal
							disableClearable
							popupIcon={null}
							PopperComponent={StyledPopper}
							options={searchList}
							onInputChange={(event, value) => setSearchQuery(value)}
							getOptionLabel={(option) => `${option.name} ${option.tags} (${option.type}:${option.id})`} 
							isOptionEqualToValue={(option, value) =>
								option.id === value.id && option.type === value.type
							}
							noOptionsText={<SearchNotFound query={searchQuery} sx={{ py: 10 }} />}
							groupBy={(option) => option.type}
							renderInput={(params) => (
								<InputBase
									{...params.InputProps}
									inputProps={params.inputProps}
									fullWidth
									autoFocus
									placeholder={`${translate("common.filter.search")}`}
									onKeyUp={handleKeyUp}
									startAdornment={
										<InputAdornment position="start">
											<IconSearch sx={{ color: "text.disabled" }} />
										</InputAdornment>
									}
									sx={{ height: 1, typography: "h6" }}
								/>
							)}
							renderOption={(props, option, { inputValue }) => {
								const { id, name, tags, type } = option;
								const partsName = parse(name, match(name, inputValue, { insideWords: true }));
								const partsTags = tags.map((tag) => {
									return parse(tag, match(tag, inputValue, { insideWords: true }));
								});

								return (
									<Box component="li" {...props} onClick={() => handleGotoElement(type, id)}>
										<div>
											{partsName.map((part, index) => (
												<Box
													key={index}
													component="span"
													sx={{
														typography: "subtitle2",
														textTransform: "capitalize",
														color: part.highlight ? "primary.main" : "text.primary",
													}}
												>
													{part.text}
												</Box>
											))}
										</div>

										<div>
											{partsTags.map((partsArray) =>
												partsArray.map((part, index) => (
													<Box
														key={index}
														component="span"
														sx={{
															typography: "caption",
															color: part.highlight ? "primary.main" : "text.secondary",
															...(index === partsArray.length - 1 && { pr: 1.5 }), // space after the last part
														}}
													>
														{part.text}
													</Box>
												))
											)}
										</div>
									</Box>
								);
							}}
						/>
					</StyledSearchbar>
				</Slide>
			</div>
		</ClickAwayListener>
	);
}

export default memo(Searchbar);

// ----------------------------------------------------------------------
