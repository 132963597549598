import { axiosInstance as axios } from "./axiosInstance";
// paths
import {
	API_WORKSPACES,
	API_WORKSPACES_ACCESS_RIGHTS,
	API_WORKSPACES_COUNT,
	API_WORKSPACES_COUNT_ACCESS_RIGHTS,
	API_WORKSPACES_SEARCH,
	API_WORKSPACES_SHARE,
	API_WORKSPACES_UNSHARE,
} from "./apiPaths";
// errors
import { ERROR_UNAUTHORIZED, ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import {
	convertDataToAccessRight,
	convertDataToWorkspace,
	convertDataToWorkspaceWithOwner,
} from "./conversions";
import {
	IAccessRight,
	IWorkspace,
	IWorkspaceWithOwner,
	WORKSPACE_ACCESS_RIGHT,
} from "src/@types/workspace";

// ---------------------------------------------------

export const apiGetWorkspace = (workspaceId: number): Promise<IWorkspace> => {
	return axios
		.get(API_WORKSPACES + "/" + workspaceId.toString(), { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToWorkspace(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiGetAllWorkspacesIHaveAccessTo = (): Promise<IWorkspaceWithOwner[]> => {
	return axios
		.get(API_WORKSPACES, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let ws: IWorkspaceWithOwner[] = [];
				for (let w in response.data) ws.push(convertDataToWorkspaceWithOwner(response.data[w]));
				return ws;
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// ---------------------------------------------------

export const apiGetAccessRights = (workspaceId: number): Promise<IAccessRight[]> => {
	return axios
		.get(API_WORKSPACES_ACCESS_RIGHTS.replace(":id", workspaceId.toString()), {
			withCredentials: true,
		})
		.then((response) => {
			if (response.data) {
				let ars: IAccessRight[] = [];
				for (let ar in response.data) ars.push(convertDataToAccessRight(response.data[ar]));
				return ars;
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiUpdateAccessRight = (workspaceId: number, userId: number, level: number) => {
	if (level === WORKSPACE_ACCESS_RIGHT.NONE) {
		return axios
			.post(
				API_WORKSPACES_UNSHARE.replace(":id", workspaceId.toString()),
				{ user_id: userId },
				{
					withCredentials: true,
				}
			)
			.catch((error) => {
				console.error(error);
				throw error.message;
			});
	} else {
		return axios
			.post(
				API_WORKSPACES_SHARE.replace(":id", workspaceId.toString()),
				{ user_id: userId, level: level },
				{
					withCredentials: true,
				}
			)
			.catch((error) => {
				console.error(error);
				throw error.message;
			});
	}
};

// ---------------------------------------------------

export const apiSearchWorkspacesByName = (searchValue: string): Promise<IWorkspaceWithOwner[]> => {
	return axios
		.post(API_WORKSPACES_SEARCH, { searchValue }, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let ws: IWorkspaceWithOwner[] = [];
				for (let w in response.data) ws.push(convertDataToWorkspaceWithOwner(response.data[w]));
				return ws;
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// ---------------------------------------------------

export const apiCreateWorkspace = (name: string, description:string, image: File | string | null): Promise<IWorkspace> => {
	// formData
	const formData = new FormData();
	formData.append("name", name);
	formData.append("description", description);
	if (!!image && image instanceof File) formData.append("image", image);
	// axios
	return axios
		.post(API_WORKSPACES, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToWorkspace(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

// ---------------------------------------------------

export const apiEditWorkspace = (
	id: number,
	name: string,
	description: string,
	image: File | string | null
): Promise<IWorkspace> => {
	// formData
	const formData = new FormData();
	formData.append("name", name);
	formData.append("description", description);
	if (!!image && image instanceof File) formData.append("image", image);
	// axios
	return axios
		.patch(API_WORKSPACES + `/${id}`, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToWorkspace(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

// ---------------------------------------------------

export const apiDeleteWorkspace = (workspaceId: number) => {
	// axios
	return axios
		.delete(API_WORKSPACES + `/${workspaceId}`, { withCredentials: true })
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

// ---------------------------------------------------

/**
 * ADMIN Get the count of all workspaces
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminWorkspaceCount = (): Promise<number> => {
	return axios
		.get(API_WORKSPACES_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};


/**
 * ADMIN Get the count of all workspace access rights
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminWorkspaceAccessRightCount = (): Promise<number> => {
	return axios
		.get(API_WORKSPACES_COUNT_ACCESS_RIGHTS, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};