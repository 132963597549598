import { axiosInstance as axios } from "./axiosInstance";
// paths
import {
	API_CONTACTS,
	API_CONTACTS_VALIDATE,
	API_CONTACTS_IGNORE,
	API_CONTACTS_CANCEL,
	API_CONTACTS_COUNT,
} from "./apiPaths";
// errors
import {
	ERROR_WRONG_RESPONSE,
	ERROR_UNKNOWN_FRIEND,
	ERROR_CANNOT_BE_YOUR_OWN_FRIEND,
	ERROR_CONTACT_REQUEST_ALREADY_EXISTS,
	ERROR_EXISTING_CONTACT,
	ERROR_UNAUTHORIZED,
} from "./apiErrors";
// conversion
import { convertDataToContact } from "./conversions";
import { CONTACT_STATUS, IContact } from "src/@types/contact";

// ---------------------------------------------------

export const apiGetAllContacts = (): Promise<IContact[]> => {
	return axios
		.get(API_CONTACTS, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let contacts: IContact[] = [];
				for (let c in response.data) contacts.push(convertDataToContact(response.data[c]));
				return contacts;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiGetAcceptedContacts = (): Promise<IContact[]> => {
	return axios
		.get(API_CONTACTS, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let contacts: IContact[] = [];
				for (let c in response.data) {
					const testContact = convertDataToContact(response.data[c]);
					if (testContact.statusCode === CONTACT_STATUS.Accepted)
						contacts.push(testContact);
				}
				return contacts;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// ---------------------------------------------------

export const validateContactRequest = (contactId: number) => {
	return axios
		.post(API_CONTACTS_VALIDATE.replace(":id", contactId.toString()), { withCredentials: true })
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiIgnoreContactRequest = (contactId: number) => {
	return axios
		.post(API_CONTACTS_IGNORE.replace(":id", contactId.toString()), { withCredentials: true })
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiCancelContactRequest = (contactId: number) => {
	return axios
		.post(API_CONTACTS_CANCEL.replace(":id", contactId.toString()), { withCredentials: true })
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

// ---------------------------------------------------

export const apiCreateContactRequest = (
	friendCode: string | null,
	email: string | null,
	message?: string
) => {
	// CHECK
	if (!friendCode && !email) throw new Error("Friend Code or Email must be provided");

	// FORM DATA
	const formData = new FormData();
	if (!!friendCode) formData.append("friend_code", friendCode);
	if (!!email) formData.append("email", email);
	if (!!message) formData.append("message", message);

	// API
	return axios
		.post(API_CONTACTS, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToContact(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// ERR_UNKNOWN_FRIEND
			if (!!error.key && error.key === "ERR_UNKNOWN_FRIEND") throw ERROR_UNKNOWN_FRIEND;
			// ERR_CANNOT_BE_YOUR_OWN_FRIEND
			else if (!!error.key && error.key === "ERR_CANNOT_BE_YOUR_OWN_FRIEND")
				throw ERROR_CANNOT_BE_YOUR_OWN_FRIEND;
			// ERR_CONTACT_REQUEST_ALREADY_EXISTS
			else if (!!error.key && error.key === "ERR_CONTACT_REQUEST_ALREADY_EXISTS")
				throw ERROR_CONTACT_REQUEST_ALREADY_EXISTS;
			// ERR_EXISTING_CONTACT
			else if (!!error.key && error.key === "ERR_EXISTING_CONTACT")
				throw ERROR_EXISTING_CONTACT;
			else throw error.message;
		});
};

/**
 * ADMIN Get the count of all contacts
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminContactCount = (): Promise<number> => {
	return axios
		.get(API_CONTACTS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};