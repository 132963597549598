import { IDates } from "./date";

export interface IWikiSearchResult {
	id: string;
	name: string; // label
	description: string;
	category: string;
}

export interface IWikiRelation {
	id: string;
	name: string;
	relation: string;
	idNum: number;
}

export interface IWikiCategory {
	id: string;
	label: string;
}

export interface IWikiPage {
	id: string;
	name: string;
	otherNames: string[];
	description: string;
	dates: IDates; // dateStart & dateEnd
	image?: string;
	sources: string[]; // source + sources (includes urls for Wiki, X, Britannica, Facebook, linkedin)
	tags: string[]; // categories + tags
	relations: IWikiRelation[];
	coordinates?: { lat: number; lon: number };
}

export const WikiCategoriesForPeriod = [
	// find the details here: https://www.wikidata.org/wiki/[id]
	"Q5", // human
	"Q198", // war
	"Q6256", // country
	"Q3024240", // historical country
	"Q350604", // armed conflict
	"Q778575", // conglomerate
	"Q43229", // organisation
	"Q245065", // intergovernmental organisation
	"Q484652", // international organisation
	"Q1127126", // military alliance
	"Q100906234", // multinational military coalition
	"Q170156", // confederation
	"Q1335818", // supranational union
	"Q4120211", // regional organisation
	"Q189445", // bicameral parliament
	"Q3623811", // economic union
	"Q15925165", // UN specialized agency
	"Q1345691", // international financial institution
	"Q35798", // executive power
	"Q1006644", // federal government
	"Q1620908", // historical region
	"Q417175", // kingdom
	"Q1250464", // kingdom
	"Q3624078", // sovereign state
	"Q1549591", // large city
	"Q7270", // republic
	"Q99541706", // historical state with limited recognition
	"Q4358176", // commission
];

