import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
// @mui
import { Box, Stack, Drawer } from "@mui/material";
// hooks
import useResponsive from "src/hooks/useResponsive";
// config
import { NAV } from "src/config-global";
// components
import Logo from "src/components/logo";
import Scrollbar from "src/components/scrollbar";
import { NavSectionVertical } from "src/components/nav-section";
//
import navConfig from "./config-navigation";
import NavToggleButton from "./NavToggleButton";
import { apiGetMyInfo } from "src/api";
// redux
import { useSelector } from "src/redux/store";
// auth
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

type Props = {
	openNav: boolean;
	onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
	const isDesktop = useResponsive("up", "lg");
	const [udpatedNavConfig, setUpdatedNavConfig] = useState(navConfig);
	// redux
	const { workspace } = useSelector((state) => state.context);
	// user
	const { user } = useAuthContext();

	// location
	const { wsId } = useParams();
	const { pathname } = useLocation();

	// dynamics nav to replace ID in the workspaces
	const getNavData = async () => {
		// Get the WS ID
		let ws = wsId as string | null | undefined; // default scenario
		if (ws === undefined || ws === null || ws === "null") {
			// check the store
			if (!!workspace && !!workspace.id) ws = workspace.id.toString();
			// check context
			else if (!!user && !!user.currentWorkspaceId) ws = user.currentWorkspaceId.toString();
			else {
				// otherwise, get CurrentWS from storage
				const storedUser = localStorage.getItem("user");
				if (!!storedUser) {
					const userjson = JSON.parse(storedUser);
					ws = userjson.currentWorkspaceId ?? userjson.personalWorkspaceId;
				} else {
					// last resort: call API
					const fetchedUser = await apiGetMyInfo();
					const wsapi = fetchedUser.currentWorkspaceId ?? fetchedUser.personalWorkspaceId;
					ws = !!wsapi ? wsapi.toString() : null;
				}
			}
		}
		// UPDATE THE MENU
		const newNavConfig = navConfig.map((section) => {
			const newItems = section.items.map((element) => {
				const newPath: string = (element.path as string).replace(
					"workspace/0/",
					`workspace/${ws}/`
				);
				return {
					...element,
					path: newPath,
				};
			});
			return { ...section, items: newItems };
		});
		// UPDATE
		setUpdatedNavConfig(newNavConfig);
	};

	useEffect(() => {
		if (openNav) {
			onCloseNav();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	useEffect(() => {
		getNavData();
		// eslint-disable-next-line
	}, [wsId, workspace]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": {
					height: 1,
					display: "flex",
					flexDirection: "column",
				},
			}}
		>
			<Stack
				spacing={3}
				sx={{
					pt: 3,
					pb: 2,
					px: 2.5,
					flexShrink: 0,
				}}
			>
				<Logo />

			</Stack>

			<NavSectionVertical data={udpatedNavConfig} />
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV.W_DASHBOARD },
			}}
		>
			<NavToggleButton />

			{isDesktop ? (
				<Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							zIndex: 0,
							width: NAV.W_DASHBOARD,
							bgcolor: "transparent",
							borderRightStyle: "dashed",
						},
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							width: NAV.W_DASHBOARD,
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}
