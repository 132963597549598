import { useRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container, BoxProps } from "@mui/material";
// hooks
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
// utils
import { bgBlur } from "src/utils/cssStyles";
// config
import { HEADER, PATH_AFTER_LOGIN } from "src/config-global";
// routes
import { PATH_AUTH } from "src/routes/paths";
// components
import Logo from "src/components/logo";
// nav
import navConfig from "./nav/config-navigation";
import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";
// locales
import { useLocales } from "src/locales";
// auth
import { useAuthContext } from "src/auth/useAuthContext";
import LanguagePopover from "./LanguagePopover";

// ----------------------------------------------------------------------

export default function Header() {
	const carouselRef = useRef(null);

	const theme = useTheme();

	const isDesktop = useResponsive("up", "md");

	const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

	const { translate } = useLocales();

	const { isAuthenticated } = useAuthContext();

	return (
		<AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
			<Toolbar
				disableGutters
				sx={{
					height: {
						xs: HEADER.H_MOBILE,
						md: HEADER.H_MAIN_DESKTOP,
					},
					transition: theme.transitions.create(["height", "background-color"], {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.shorter,
					}),
					...(isOffset && {
						...bgBlur({ color: theme.palette.background.default }),
						height: {
							md: HEADER.H_MAIN_DESKTOP - 16,
						},
					}),
				}}
			>
				<Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
					<Logo />

					<Box sx={{ flexGrow: 1 }} />

					{isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}

					{isAuthenticated ? (
						<Button variant="contained" href={PATH_AFTER_LOGIN}>
							{`${translate("nav.dashboard")}`}
						</Button>
					) : (
						<>
							{isDesktop && (
								<Button variant="outlined" href={PATH_AUTH.login} sx={{ mx: 2 }}>
									{`${translate("nav.login")}`}
								</Button>
							)}

							<Button variant="contained" href={PATH_AUTH.register}>
								{`${translate("nav.sign_up_for_free")}`}
							</Button>
						</>
					)}

					<Box sx={{ flexGrow: 1 }} />
					{/* <Box sx={{ mx:2 }} /> */}

					<LanguagePopover />

					{!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
				</Container>
			</Toolbar>

			{isOffset && <Shadow />}
		</AppBar>
	);
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
	return (
		<Box
			sx={{
				left: 0,
				right: 0,
				bottom: 0,
				height: 24,
				zIndex: -1,
				m: "auto",
				borderRadius: "50%",
				position: "absolute",
				width: `calc(100% - 48px)`,
				boxShadow: (theme) => theme.customShadows.z8,
				...sx,
			}}
			{...other}
		/>
	);
}
