// routes
import { PATH_PAGE } from 'src/routes/paths';
// components
import { IconHome, IconTarget, IconWhoWeAre, IconContactUs } from 'src/icones/icones';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'nav.home',
    icon: <IconHome />,
    path: '/',
  },
  {
    title: 'nav.who_it_is_for',
    icon: <IconTarget />,
    path: PATH_PAGE.target,
  },
  {
    title: 'nav.who_we_are',
    icon: <IconWhoWeAre />,
    path: PATH_PAGE.about,
  },
  {
    title: 'nav.where_to_find_us',
    icon: <IconContactUs />,
    path: PATH_PAGE.contact,
  }, 
];

export default navConfig;
