import { createSlice, Dispatch } from "@reduxjs/toolkit";
// @types
import { ITasksState, ITask } from "../../@types/task";
import { apiCreateTask, apiDeleteTask, apiGetTasksByWorkspace, apiUpdateTask } from "src/api/task";

// ----------------------------------------------------------------------

const initialState: ITasksState = {
	isLoading: false,
	error: null,
	tasks: [],
};

const slice = createSlice({
	name: "tasks",
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		// GET BOARD
		getTasksByWorkspace(state, action) {
			state.isLoading = false;
			state.tasks = action.payload;
		},

		addTask(state, action) {
			const task = action.payload;
			state.tasks.push(task);
		},

		updateTask(state, action) {
			const task = action.payload;
			const index = state.tasks.findIndex((t) => t.id === task.id);
			if (index !== -1) state.tasks[index] = task;
		},

		deleteTask(state, action) {
			const id = action.payload;
			state.tasks = state.tasks.filter((task) => task.id !== id);
		},
	},
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getTasksByWorkspaceSuccess(workspaceId: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const tasks = await apiGetTasksByWorkspace(workspaceId);
			dispatch(slice.actions.getTasksByWorkspace(tasks));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function addTask(workspaceId: number, task: Partial<ITask>) {
	return async (dispatch: Dispatch) => {    
			const createdTask = await apiCreateTask(workspaceId, task);
		dispatch(slice.actions.addTask(createdTask));
	};
}

// ----------------------------------------------------------------------

export function updateTask(task: ITask) {
	return async (dispatch: Dispatch) => {
    const updatedTask = await apiUpdateTask(task);
		dispatch(slice.actions.updateTask(updatedTask));
	};
}

// ----------------------------------------------------------------------

export function deleteTask(taskId: number) {
	return async (dispatch: Dispatch) => {
    await apiDeleteTask(taskId);
		dispatch(slice.actions.deleteTask(taskId));
	};
}
