// routes
import { PATH_ADMIN } from "src/routes/paths";
// icons
import { IconMain, IconUsers, IconEvents } from "src/icones/icones";

// ----------------------------------------------------------------------

const navConfig = [
	// ELEMENTS
	// ----------------------------------------------------------------------
	{
		subheader: "Main",
		items: [{ title: "Dashboard", path: PATH_ADMIN.dashboard, icon: <IconMain /> }],
	},
	{
		subheader: "Details",
		items: [
			{ title: "Users", path: PATH_ADMIN.users, icon: <IconUsers /> },
			{ title: "Events", path: PATH_ADMIN.events, icon: <IconEvents /> },
		],
	},
];

export default navConfig;
