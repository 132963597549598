import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_POSITIONS } from "./apiPaths";
import { IGraphPosition } from "src/@types/element";
import { convertDataToGraphPosition } from "./conversions";

// ------------------ UPDATE ONE ---------------------------------

export const apiUpdatePosition = (position: IGraphPosition) => {
	const postData = {
		element_id: position.id,
		x: position.x,
		y: position.y,
	};
	// AXIOS
	return axios.post(API_POSITIONS, postData, { withCredentials: true }).catch((error) => {
		console.error(error);
		// we don't know :'(
		throw error.message;
	});
};

// ------------------ UPDATE ALL ---------------------------------

export const apiUpdateAllPositions = (positions: IGraphPosition[]) => {
	const pos = positions.map((position) => {
		return {
			element_id: position.id,
			x: position.x ?? 0,
			y: position.y ?? 0,
		};
	});
	const postData = {
		positions: pos,
	};
	// AXIOS
	return axios.post(API_POSITIONS + "/all", postData, { withCredentials: true }).catch((error) => {
		console.error(error);
		// we don't know :'(
		throw error.message;
	});
};

// ------------------ GET ALL ---------------------------------

export const apiGetAllPositions = (workspaceId: number) => {
	return axios
		.get(API_POSITIONS + `/getAll/${workspaceId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let positions: IGraphPosition[] = [];
				for (let p in response.data) positions.push(convertDataToGraphPosition(response.data[p]));
				return positions;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};
